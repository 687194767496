import { useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { api } from '../utils/http'
import {
  ISubscriptions,
  ICurrentPayment,
  TInitialPayType,
} from './subscription'
import { AxiosError, AxiosResponse } from 'axios'
import { notify, ToastIcon } from '../components/Toast'
import { ALERT_CLOSE_WAIT } from '../constants'
import { IError } from './error'
import { useLocalStorage } from 'usehooks-ts'
import { ILocalStorage } from '../hooks/useLocalStorage/useLocalStorage'
import Metrika from '../utils/Metrika'

// public const ACCESS_TYPE_PAY = 1;
// public const ACCESS_TYPE_FREE = 2;
// public const ACCESS_TYPE_PREMIUM = 3;
export enum TCurrency {
  RUB = 'RUB',
  AMD = 'AMD',
  RUB_SYMBOL = '₽',
  AMD_SYMBOL = 'Դ',
}
export type BaseLesson = {
  title: string
  type: number
  number: number | null
  days: number
  imagePath: string | null
}

export interface Module {
  title: string
  lessons: BaseLesson[]
  number: number | null
}

export interface Product {
  slug: string // slug всегда уникальным
  title: string
  description: string | null
  fullPayDiscountPercent: number // Скидка за покупку всего продукта, в процентах
  modulesCount: number
  lessonsCount: number
  category: {
    title: string
    slug: string
  }
  currency: string | null
  accessType: number // Тип доступа к продукту
  dayPrice: number | null
  fullPrice: number
  totalDays: number
  course: {
    title: string
  }
  initialAmounts: {
    [type: string]: number
  } | null
  subscription?: {
    status: number
    accessType: number
    currentPayment: ICurrentPayment | null
    canBeSurcharged: boolean
    initialPayType: TInitialPayType
    stopReason?: number
  }
  imagePath: string | null
  nextBlock: {
    days: string
    price: string
  } | null
  installment?: {
    amount: number
    systems: string[]
  }
}

export interface Product2 extends Product {
  course: {
    title: string
    modules: Module[]
  }
  isSubscribed: boolean
}

export type Products = {
  total: number
  offset: number
  limit: number
  items: Product[]
}

export type TRegistrationBody = {
  headers: { [key: string]: string }
  slug: string
  email: string
  recaptcha: string
  utm?: string
  type?: TInitialPayType
  redirectPath?: string
}

type TRegistrationResponse = {
  success: boolean
  token: string
  subscription: ISubscriptions
  redirectUrl?: string
}
const productApi = {
  getProducts: (options: IGetProductsOptions = {}): Promise<Products> => {
    return api
      .get(`/api/products`, {
        params: {
          offset: options?.offset,
          limit: options?.limit || 23,
          categorySlug: options?.categorySlug,
        },
        headers: options?.headers,
      })
      .then(res => res.data)
  },
  getProductsSlug: (
    productSlug: string = '',
    headers?: { [key: string]: string },
  ): Promise<Product2> => {
    return api
      .get(`/api/products/${productSlug}`, { headers })
      .then(res => res.data)
  },
  postProductSubscribe: (
    headers: { [key: string]: string },
    slug: string,
    type?: TInitialPayType,
  ): Promise<AxiosResponse<ISubscriptions>> => {
    const path = type
      ? `/api/products/${slug}/subscribe?type=${type}`
      : `/api/products/${slug}/subscribe`
    return api.post(path, null, { headers })
  },
  postProductRegistration: (
    data: Omit<TRegistrationBody, 'headers'>,
  ): Promise<AxiosResponse<TRegistrationResponse>> => {
    const { email, recaptcha, slug, type, redirectPath } = data
    const url = data.utm
      ? `/api/products/${slug}/registration` + `${data.utm}`
      : `/api/products/${slug}/registration`
    const body = redirectPath
      ? {
          email,
          initialPayType: type,
          recaptcha,
          redirectPath,
        }
      : {
          email,
          initialPayType: type,
          recaptcha,
        }
    return api.post(url, body)
  },
}

interface IGetProductsOptions {
  offset?: string
  limit?: string
  categorySlug?: string
  headers?: {
    [key: string]: string
  }
  isLoggedIn?: boolean
}

export const useProductQuery = {
  useGetProducts: (options: IGetProductsOptions = {}) => {
    return useQuery<Products, AxiosError<IError>>(
      [
        'getProducts',
        {
          categorySlug: options?.categorySlug ?? '',
        },
        options.isLoggedIn,
      ],
      () => productApi.getProducts(options),
    )
  },
  useGetProductsSlug: (
    productSlug: string = '',
    isLoggedIn: boolean = false,
    headers?: { [key: string]: string },
    options?: {
      enabled: boolean
    },
  ) => {
    return useQuery<Product2, AxiosError<IError>>(
      ['getProductsSlug', productSlug, isLoggedIn],
      () => productApi.getProductsSlug(productSlug, headers),
      options
        ? options
        : {
            enabled: !!productSlug,
          },
    )
  },
}

interface TProductSubscribeBody {
  headers: {
    [key: string]: string
  }
  slug: string
  type?: TInitialPayType
  isAuth: boolean
}

export const useProductMutation = {
  usePostProductSubscribeMutation: () =>
    useMutation<
      AxiosResponse<ISubscriptions>,
      AxiosError<IError>,
      TProductSubscribeBody
    >(
      formData =>
        productApi.postProductSubscribe(
          formData.headers,
          formData.slug,
          formData.type,
        ),
      {
        onError: ({ response }) => {
          notify({
            icon: ToastIcon.ERROR,
            autoClose: ALERT_CLOSE_WAIT,
            label: response?.data?.error?.message ?? '',
          })
        },
      },
    ),
  usePostProductRegistrationMutation: () => {
    const [metrika] = useState(() => new Metrika())
    const [local] = useLocalStorage<ILocalStorage>('willskill', {
      cookieAgree: false,
      utm: '',
    })
    return useMutation<
      AxiosResponse<TRegistrationResponse>,
      AxiosError<IError>,
      Omit<TRegistrationBody, 'headers'>
    >(
      formData => {
        if (local.utm) {
          formData.utm = decodeURIComponent(local.utm)
        }
        return productApi.postProductRegistration(formData)
      },
      {
        onSuccess: () => {
          metrika
            .willskillYandex({
              methos: 'reachGoal',
              targetNames: 'new_reg',
            })
            .offerrmYandex({
              methos: 'reachGoal',
              targetNames: 'new_reg',
            })
            .offerrmVk({
              methos: 'reachGoal',
              targetNames: 'new_reg',
            })
        },
      },
    )
  },
}

export default productApi
