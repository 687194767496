import 'react-toastify/dist/ReactToastify.css'
import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { useRouter } from 'next/router'
import Reset from '../styles/reset'
import Normalize from '../styles/normalize'
import GlobalStyle from '../styles/globalStyle'
import theme from '../styles/themes/theme'
import AppProvider from '../context'
import { SessionProvider, useSession, signIn, signOut } from 'next-auth/react'
import { AppAuthProps } from '../utils/auth'
import { ToastContainer } from 'react-toastify'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import getConfig from 'next/config'
import { appWithTranslation, useTranslation } from 'next-i18next'
/**
 * ui
 */
import LoadingTemplate from 'src/components/Template/LoadingTemplate'
import Scripts from 'src/components/Scripts'
/**
 * utils
 */
import checkCookieName from 'src/utils/checkCookieName'
// import useScrollRestoration from 'src/hooks/useScrollRestoration'
// api
import { useSecureMutation } from 'src/api/secure'
// constants
import { ALERT_CLOSE_WAIT } from 'src/constants'
// components
import { notify, ToastIcon } from 'src/components/Toast'
//context
import { useAppState } from 'src/context/app'

const {
  publicRuntimeConfig: { RECAPTCHA_SITE_KEY, EXPORT, INTERNALIZATION },
} = getConfig()

const CheckRecaptchaKey = ({ children }: React.PropsWithChildren) => {
  const { recaptcha } = useAppState()
  const { i18n } = useTranslation()

  return recaptcha.length > 0 ? (
    <GoogleReCaptchaProvider reCaptchaKey={recaptcha} language={i18n.language}>
      {children}
    </GoogleReCaptchaProvider>
  ) : (
    <>{children}</>
  )
}

const OneTimePassword = ({ children }: any) => {
  const router = useRouter()
  const { status } = useSession()
  const { t } = useTranslation('common')

  const handleMagicLink = useSecureMutation.usePostMagicLink()

  React.useEffect(() => {
    // 만약 로그인 되어 있다면, 쿼리스트링에서 mtoken 을 지우기.
    if (status === 'authenticated' && router.query.mtoken) {
      // @ts-ignore
      const params = new URLSearchParams(router.query)
      params.delete('mtoken')
      router
        .replace(
          { pathname: router.pathname, query: params.toString() },
          undefined,
          { shallow: true },
        )
        .then(() => children)
    }
    // 만약 로그인이 안되어있고, mtoken이 있다면.
    if (status === 'unauthenticated' && router.query.mtoken) {
      handleMagicLink
        .mutateAsync({ mtoken: router.query.mtoken as string })
        .then(async res => {
          await signIn('loginwithMagicLink', {
            redirect: false,
            token: res.data.token,
          })
          return children
        })
        .catch(() => {
          notify(
            {
              icon: ToastIcon.ERROR,
              autoClose: ALERT_CLOSE_WAIT,
              label: t('notify.accessDenined'),
            },
            () => {
              return router.push('/?modal=login')
            },
          )
        })
    }
  }, [status])

  return children
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Auth = ({ children }: any) => {
  const { data: session, status } = useSession()
  const isUser = !!session?.token

  React.useEffect(() => {
    if (status === 'loading') return
    // Если требуется не авторизованног на страницу protect
    // redirect to '/?modal=login'
    if (!isUser) {
      signOut({ callbackUrl: '/?modal=login' })
    }
  }, [isUser, status])

  if (isUser) {
    return children
  }

  // Session is being fetched, or no user.
  // If no user, useEffect() will redirect.
  return <LoadingTemplate />
}

const MyApp = ({ Component, pageProps }: AppAuthProps) => {
  const router = useRouter()
  // useScrollRestoration(router)
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  )
  // set locale when in cookie has locale
  React.useEffect(() => {
    const { pathname, asPath, query, locale } = router
    if (typeof window !== 'undefined') {
      const match = checkCookieName('NEXT_LOCALE')
      if (match && match !== locale) {
        router.push({ pathname, query }, asPath, { locale: match })
      }
    }
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <AppProvider
          internalization={JSON.parse(INTERNALIZATION) as boolean}
          exports={JSON.parse(EXPORT) as boolean}
          recaptcha={RECAPTCHA_SITE_KEY}
        >
          <CheckRecaptchaKey>
            <ThemeProvider theme={theme}>
              <Reset />
              <Normalize />
              <GlobalStyle />
              {Component.auth?.require ? (
                <OneTimePassword>
                  <Auth>
                    <Scripts />
                    <Component {...pageProps} />
                    <ToastContainer />
                  </Auth>
                </OneTimePassword>
              ) : (
                <OneTimePassword>
                  <Scripts />
                  <Component {...pageProps} />
                  <ToastContainer />
                </OneTimePassword>
              )}
            </ThemeProvider>
          </CheckRecaptchaKey>
        </AppProvider>
      </Hydrate>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  )
}

const MyAppExport = ({ Component, pageProps }: AppAuthProps) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
  )
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <AppProvider
          internalization={JSON.parse(INTERNALIZATION) as boolean}
          exports={JSON.parse(EXPORT) as boolean}
        >
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <Component {...pageProps} />
          </ThemeProvider>
        </AppProvider>
      </Hydrate>
    </QueryClientProvider>
  )
}

const AppWithI18n = appWithTranslation(MyApp)

const AppWithAuth = (props: AppAuthProps) => (
  <SessionProvider
    session={props.pageProps.session}
    basePath='auth'
    refetchOnWindowFocus={false}
  >
    <AppWithI18n {...props} />
  </SessionProvider>
)

export default EXPORT === 'false' ? AppWithAuth : MyAppExport
