import React, { useEffect } from 'react'
import styled from 'styled-components'
import Image from 'next/image'
import getConfig from 'next/config'
import debounce from 'lodash/debounce'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import { ru } from 'date-fns/locale'
import { format } from 'date-fns'
/**
 * ui
 */
import Button, { ButtonSize, ButtonStyle } from '../Button'
import Radio from '../Radio'
/**
 * context
 */
import { useAppState, useAppDispatch } from '../../context/app'
/**
 * api
 */
import {
  useSubscriptionsMutation,
  useSubscriptions,
} from '../../api/subscription'
import { useProductQuery } from '../../api/product'
/**
 * hook
 */
import useHeaders from '../../hooks/useHeaders'
import usePayments from '../../hooks/usePayments'
import useWindowSize from '../../hooks/useWindowSize/useWindowSize'
/**
 * constants
 */
import { DEBOUNCE_WAIT, OFERTA } from '../../constants'
import { TModal } from './Modal'

const {
  publicRuntimeConfig: { STATIC_ASSETS_COMMON_URL },
} = getConfig()

type TFormValue = {
  systems: string
  duration: {
    [system: string]: number
  }
}

const ModalInstallment: React.FC = () => {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const { slug, cancelCurrent } = useAppState()
  const { headers, status } = useHeaders()
  const { handlePayment } = usePayments()
  const { width } = useWindowSize()
  const { register, setValue, watch, handleSubmit } = useForm<TFormValue>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      systems: 'sbercredit',
      duration: {
        yandexPay: 6,
        sbercredit: 36,
        tinkoff: 36,
      },
    },
  })

  const systemsValue = watch('systems')
  const durationValue = watch('duration')
  // queries
  const { data, isLoading } = useSubscriptions.useGetSubscriptionBySlug(
    headers,
    slug,
  )
  const { data: productData, isLoading: productIsLoading } =
    useProductQuery.useGetProductsSlug(
      slug,
      status === 'authenticated',
      headers,
    )
  // mutation
  const installmentMutation =
    useSubscriptionsMutation.usePostSubscriptionsInstallment()

  // to register state of useForm
  useEffect(() => {
    register('systems')
    register('duration')
  }, [register])

  if (!data || isLoading || !productData || productIsLoading) {
    return null
  }
  const onSubmit: SubmitHandler<TFormValue> = data => {
    const body = cancelCurrent
      ? {
          system: data.systems,
          cancelCurrent: true,
        }
      : {
          system: data.systems,
        }
    return installmentMutation
      .mutateAsync({
        headers,
        slug,
        body,
      })
      .then(response => {
        dispatch({
          type: 'SET_CANCEL_CURRENT',
          cancelCurrent: false,
        })
        handlePayment(response.data)
      })
      .catch(() => {
        dispatch({
          type: 'SET_MODAL',
          modalShown: false,
          modalType: TModal.BLANK,
        })
      })
  }
  const getSplitLength = (value: number, width: number) => {
    if (value === 2) return value * 2
    if (value === 36 && width > 743.98) return 26
    if (value === 36 && width < 743.98) return 12
    return value
  }
  const getSplitDate = (
    index: number,
    durationValue: number,
    system: string,
  ) => {
    if (durationValue === 2 && system === 'yandexPay') {
      return index === 0
        ? currentDay
        : new Date(currentDay).setDate(currentDay.getDate() + 14 * (index + 1))
    }
    if (system === 'sbercredit') {
      return new Date(currentDay).setMonth(currentDay.getMonth() + (index + 2))
    }
    if (system === 'tinkoff') {
      const date = new Date(currentDay)
      date.setDate(date.getDate() + 30)
      return index === 0
        ? date
        : new Date(currentDay).setDate(currentDay.getDate() + 30 * (index + 1))
    }
    return index === 0
      ? currentDay
      : new Date(currentDay).setMonth(currentDay.getMonth() + index)
  }
  const renderNumber = (system: string) => {
    if (system === 'tinkoff') {
      const date = new Date()
      date.setDate(date.getDate() + 30)
      return date.getDate()
    }
    return currentDay.getDate()
  }
  const handler = debounce(() => {
    handleSubmit(onSubmit)()
  }, DEBOUNCE_WAIT)

  const currentDay = new Date()
  // 743.98
  return (
    <StyledContainer
      onSubmit={e => {
        e.preventDefault()
        handler()
      }}
    >
      <StyledImageWrapper>
        <Image
          src={`${STATIC_ASSETS_COMMON_URL}/assets/images/components-modal-logo.png`}
          alt='background'
          width={509}
          height={381}
          priority
        />
      </StyledImageWrapper>

      <StyledTitle>
        Купить в рассрочку {data.fullPayDiscountPercent && `со скидкой`}
        {data.installment && data.installment.amount && (
          <StyledTitleDiscount>
            За {t('intlNumber', { val: data.installment.amount / 100 })} ₽
            <StyledTitleDiscountUnderline>
              <span
                style={{
                  textDecorationLine: 'line-through',
                }}
              >
                {t('intlNumber', { val: productData.fullPrice / 100 })} ₽
              </span>{' '}
              {productData.fullPayDiscountPercent && (
                <StyledDiscountTag>
                  -{productData.fullPayDiscountPercent}%
                </StyledDiscountTag>
              )}
            </StyledTitleDiscountUnderline>
          </StyledTitleDiscount>
        )}
      </StyledTitle>
      <StyledSubtitle>
        <StyledSubtitleText>
          Заполните анкету и получите одобрение в течение нескольких минут
        </StyledSubtitleText>
        {data.installment && data.installment.amount && (
          <StyledSubtitleTitleDiscount>
            За {t('intlNumber', { val: data.installment.amount / 100 })} ₽
            <StyledTitleDiscountUnderline>
              <span
                style={{
                  textDecorationLine: 'line-through',
                }}
              >
                {t('intlNumber', { val: productData.fullPrice / 100 })} ₽
              </span>{' '}
              {productData.fullPayDiscountPercent && (
                <StyledDiscountTag>
                  -{productData.fullPayDiscountPercent}%
                </StyledDiscountTag>
              )}
            </StyledTitleDiscountUnderline>
          </StyledSubtitleTitleDiscount>
        )}
      </StyledSubtitle>
      <StyledInstallmentContainer>
        {data.installment &&
          data.installment.systems
            .map(system => {
              if (system === 'yandexPay') {
                return {
                  system,
                  title: 'В рассрочку со ',
                  titleAccent: 'Сплитом',
                  image: `${STATIC_ASSETS_COMMON_URL}/assets/images/components-modal-split.png`,
                  imageWidth: 109,
                  imageHeight: 21.8,
                  durations: [2, 4, 6],
                }
              }
              if (system === 'sbercredit') {
                return {
                  system,
                  title: 'В рассрочку со ',
                  titleAccent: 'Сбером',
                  subTitle: 'Без первоначального взноса и переплат',
                  image: `${STATIC_ASSETS_COMMON_URL}/assets/images/components-modal-sber2.png`,
                  imageWidth: 109,
                  imageHeight: 26,
                  durations: [3, 6, 12, 36],
                  imageHref:
                    'https://www.sberbank.com/ru/person/credits/money/pos',
                  description:
                    'Только для клиентов банка. Заявка подается через СберБанк Онлайн.',
                  comments: 'Первый платеж через 2 месяца',
                }
              }
              if (system === 'tinkoff') {
                return {
                  system,
                  title: 'В рассрочку с ',
                  titleAccent: 'Тинькофф',
                  subTitle: 'Без первоначального взноса и переплат',
                  image: `${STATIC_ASSETS_COMMON_URL}/assets/images/components-modal-tinkoff.png`,
                  imageWidth: 109,
                  imageHeight: 25.82,
                  durations: [3, 6, 12, 36],
                  comments: 'Первый платеж через 30 дней',
                }
              }
              return {
                system: 'dumb',
                title: 'В рассрочку с ',
                titleAccent: 'dumb',
              }
            })
            .map((obj, index) => (
              <StyledInstallmentItem
                key={index}
                checked={systemsValue === obj.system}
                onClick={() => {
                  setValue('systems', obj.system)
                }}
              >
                <StyledInstallmentItemTop>
                  <StyledRadio>
                    <Radio checked={systemsValue === obj.system} />
                  </StyledRadio>

                  <StyledItemInner>
                    <StyledItemInnerText checked={systemsValue === obj.system}>
                      {obj.title}
                      <StyledItemInnerTextAccent>
                        {obj.titleAccent}
                      </StyledItemInnerTextAccent>
                    </StyledItemInnerText>
                    {obj.image && !obj.imageHref && (
                      <StyledItemInnerImage>
                        <Image
                          width={obj.imageWidth}
                          height={obj.imageHeight}
                          alt={obj.title}
                          src={obj.image}
                          unoptimized
                        />
                      </StyledItemInnerImage>
                    )}

                    {obj.image && obj.imageHref && (
                      <StyledItemInnerImage
                        as='a'
                        href={obj.imageHref}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <Image
                          width={obj.imageWidth}
                          height={obj.imageHeight}
                          alt={obj.title}
                          src={obj.image}
                          unoptimized
                        />
                      </StyledItemInnerImage>
                    )}
                  </StyledItemInner>
                </StyledInstallmentItemTop>

                {obj.subTitle && systemsValue === obj.system && (
                  <StyledSubtitle2Text>{obj.subTitle}</StyledSubtitle2Text>
                )}

                {systemsValue === obj.system && (
                  <StyledInstallmentItemBottom>
                    {obj.durations && (
                      <StyledSplitMonthContainer length={obj.durations.length}>
                        {obj.durations.map(value => (
                          <StyledSplitMonth
                            key={value.toString()}
                            aria-checked={durationValue[obj.system] === value}
                            onClick={() => {
                              setValue(`duration.${obj.system}'`, value)
                            }}
                          >
                            {t('month', { count: value })}
                          </StyledSplitMonth>
                        ))}
                      </StyledSplitMonthContainer>
                    )}
                    {data.installment &&
                      data.installment.amount &&
                      obj.system === 'yandexPay' && (
                        <StyledSelectedPriceContainer>
                          <StyledSelectedPrice>
                            {t('intlNumber', {
                              val: Number(
                                (
                                  data.installment.amount /
                                  getSplitLength(
                                    durationValue.yandexPay,
                                    width,
                                  ) /
                                  100
                                ).toFixed(0),
                              ),
                            })}{' '}
                            ₽
                          </StyledSelectedPrice>
                          <StyledSelectedPriceTag>
                            сейчас
                          </StyledSelectedPriceTag>
                        </StyledSelectedPriceContainer>
                      )}
                    {obj.comments && (
                      <StyledCommentText>{obj.comments}</StyledCommentText>
                    )}
                    {data.installment && data.installment.amount && (
                      <StyledSplitContainer>
                        <StyledSplitItem>
                          {Array.from({
                            length: getSplitLength(
                              durationValue[obj.system],
                              width,
                            ),
                          }).map((star, index) => (
                            <StyledSplitItemHeader
                              key={index.toString()}
                              order={index}
                              durationValue={durationValue[obj.system]}
                            />
                          ))}
                        </StyledSplitItem>

                        <StyledSplitItem2>
                          {Array.from({
                            length: getSplitLength(
                              durationValue[obj.system],
                              width,
                            ),
                          }).map((star, index) => {
                            return (
                              <StyledSplitItemMonth
                                key={index}
                                order={index}
                                durationValue={durationValue[obj.system]}
                              >
                                <div>
                                  {format(
                                    getSplitDate(
                                      index,
                                      durationValue[obj.system],
                                      obj.system,
                                    ),
                                    'dd MMM',
                                    {
                                      locale: ru,
                                    },
                                  )}
                                </div>
                                {data.installment && data.installment.amount && (
                                  <StyledSplitItemPrice>
                                    {t('intlNumber', {
                                      val: Number(
                                        (
                                          data.installment.amount /
                                          getSplitLength(
                                            durationValue[obj.system],
                                            width,
                                          ) /
                                          100
                                        ).toFixed(0),
                                      ),
                                    })}{' '}
                                    ₽
                                  </StyledSplitItemPrice>
                                )}
                              </StyledSplitItemMonth>
                            )
                          })}
                        </StyledSplitItem2>

                        <StyledSplitItem3
                          durationValue={durationValue[obj.system]}
                        >
                          <StyledSplitItem3Left>
                            <div>
                              {format(
                                getSplitDate(
                                  0,
                                  durationValue[obj.system],
                                  obj.system,
                                ),
                                'dd MMM',
                                {
                                  locale: ru,
                                },
                              )}
                            </div>
                            <StyledSplitItemPrice>
                              {t('intlNumber', {
                                val: Number(
                                  (
                                    data.installment.amount /
                                    durationValue[obj.system] /
                                    100
                                  ).toFixed(0),
                                ),
                              })}{' '}
                              ₽
                            </StyledSplitItemPrice>
                          </StyledSplitItem3Left>
                          <StyledSplitItem3Right>
                            Еще {durationValue[obj.system] - 1} платежей каждое{' '}
                            {renderNumber(obj.system)}
                            -е число
                          </StyledSplitItem3Right>
                        </StyledSplitItem3>
                        {obj.description && (
                          <StyledInstallmentItemDescription>
                            {obj.description}
                          </StyledInstallmentItemDescription>
                        )}
                      </StyledSplitContainer>
                    )}
                  </StyledInstallmentItemBottom>
                )}
              </StyledInstallmentItem>
            ))}
      </StyledInstallmentContainer>
      <Button
        buttonStyle={ButtonStyle.FILLED}
        size={ButtonSize.BLOCK}
        type='submit'
      >
        Перейти к оплате
      </Button>

      <StyledAgreeText>
        Нажимая на кнопку, я соглашаюсь{' '}
        <StyledConsultantAcceptLink
          target='_blank'
          href={OFERTA}
          rel='noreferrer'
        >
          с публичной офертой
        </StyledConsultantAcceptLink>
      </StyledAgreeText>
    </StyledContainer>
  )
}

const StyledContainer = styled.form`
  background: #ffffff;
  box-shadow: 17px 41px 64px rgba(0, 0, 0, 0.07);
  border-radius: 30px;
  width: 100%;
  /* position: relative; */
  padding: 20px 16px 70px 16px;
  max-width: 288px;
  overflow: hidden;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 40px 60px 72px 60px;
    width: 640px;
    max-width: 100%;
  }
`

const StyledImageWrapper = styled.div({
  position: 'absolute',
  top: '-30px',
  right: '-110px',
})

const StyledTitle = styled.h4`
  z-index: 2;
  font-family: 'Averta PE', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
  ${({ theme }) => theme.mixins.H4}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    flex-direction: row;
    justify-content: space-between;
    ${({ theme }) => theme.mixins.H3}
    margin-bottom: 16px;
  }
`

const StyledTitleDiscount = styled.div`
  ${({ theme }) => theme.mixins.H5}
  margin-top: 11px;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: none;
    margin-top: 0;
    ${({ theme }) => theme.mixins.H3}
    flex-direction: column;
  }
`

const StyledTitleDiscountUnderline = styled.div`
  display: flex;
  align-items: center;
  color: #757575;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-left: 10px;
  gap: 10px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-size: 20px;
    line-height: 25px;
    justify-content: space-between;
    margin-left: 0;
  }
`

const StyledDiscountTag = styled.div`
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 24px;
  background: #fb432e;
  border-radius: 30px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  text-decoration: none;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 61px;
    height: 31px;
    ${({ theme }) => theme.mixins.H5}
  }
`

const StyledSubtitle = styled.div`
  margin-bottom: 20px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 23px;
    align-items: end;
    ${({ theme }) => theme.mixins.textNormal}
  }
`
const StyledSubtitleText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(25, 25, 25, 0.5);

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 322px;
    ${({ theme }) => theme.mixins.textNormal}
  }
`

const StyledSubtitleTitleDiscount = styled(StyledTitleDiscount)`
  display: none;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: flex;
  }
`

const StyledInstallmentContainer = styled.ul`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.flexDirection('column')}
  gap: 10px;
  margin-bottom: 30px;
  background: #fff;
  z-index: 1;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    gap: 10px;
    margin-bottom: 32px;
  }
`
const StyledRadio = styled.div`
  align-self: baseline;
  padding-top: 4px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding-top: 0;
    align-self: initial;
  }
`

const StyledInstallmentItem = styled.li<{ checked: boolean }>`
  background-color: #fff;
  padding: 12px 16px 15px;
  border: 1px solid #dedede;
  border-radius: 10px;
  cursor: pointer;
  z-index: 2;
  box-shadow: ${({ checked }) =>
    checked ? '17px 41px 64px rgba(0, 0, 0, 0.07)' : ''};
`
const StyledInstallmentItemTop = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    gap: 16px;
  }
`

const StyledInstallmentItemDescription = styled.p`
  color: rgba(25, 25, 25, 0.5);
  margin-top: 3px;
  ${({ theme }) => theme.mixins.textSmall4}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.textSmall}
    margin-top: 16px;
  }
`

const StyledItemInner = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #747474;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

const StyledItemInnerImage = styled.div`
  display: flex;
  align-items: center;
`

const StyledItemInnerText = styled.p<{ checked: boolean }>`
  padding-top: 2px;
  margin-bottom: 8px;
  ${({ theme }) => theme.mixins.textSmall3}

  @media ${({ theme }) => theme.devices.mobileMdUp} {
    padding-top: 0;
    margin-bottom: 0;
    ${({ theme }) => theme.mixins.textNormal}
  }
`

const StyledItemInnerTextAccent = styled.strong`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
`

const StyledInstallmentItemBottom = styled.div``

const StyledSplitMonthContainer = styled.div<{ length: number }>`
  margin-top: 15px;
  background: #e5e7eb;
  border-radius: 30px;
  display: grid;
  grid-template-columns: repeat(${({ length }) => length}, 1fr);
`

const StyledSplitMonth = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  ${({ theme }) => theme.mixins.textSmall3}
  border-radius: 30px;
  &[aria-checked='true'] {
    color: #fff;
    background: #474747;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.textNormal}
    height: 35px;
  }
`

const StyledSelectedPriceContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 5px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    gap: 15px;
  }
`

const StyledSelectedPrice = styled.div`
  color: #303030;
  ${({ theme }) => theme.mixins.textButton2}

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H4}
  }
`
const StyledSelectedPriceTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #474747;
  border-radius: 30px;
  color: #ffffff;
  ${({ theme }) => theme.mixins.textSmall}
  padding: 0 10px;
  padding-bottom: 3px;
  width: 67px;
  height: 20px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.textNormal}
    height: 23px;
    width: 73px;
  }
`

const StyledSplitContainer = styled.div`
  margin-top: 15px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 20px;
  }
`

const StyledSplitItem = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    gap: 6px;
  }
`

const StyledSplitItemHeader = styled.div<{
  order: number
  durationValue: number
}>`
  width: 100%;
  height: 5px;
  border-radius: 20px;
  background: #d1eee0;
  flex-basis: ${({ order, durationValue }) =>
    order === 0 && durationValue === 12
      ? '350%'
      : order === 0 && durationValue === 36
      ? '350%'
      : ''};

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    flex-basis: ${({ order, durationValue }) =>
      order === 0 && durationValue === 12
        ? '200%'
        : order === 0 && durationValue === 36
        ? '350%'
        : ''};
  }
`

const StyledSplitItem2 = styled(StyledSplitItem)`
  margin-top: 5px;
`

const StyledSplitItemMonth = styled.div<{
  order: number
  durationValue: number
}>`
  width: 100%;
  ${({ theme }) => theme.mixins.textSmall3}
  flex-basis: ${({ order, durationValue }) =>
    order === 0 && durationValue > 5 ? '150%' : ''};
  display: ${({ durationValue }) => (durationValue > 5 ? 'none' : '')};
`

const StyledSplitItemPrice = styled.div`
  margin-top: 5px;
  font-weight: 700;
  line-height: 120%;
  font-size: 14px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-size: 16px;
  }
`

const StyledSplitItem3 = styled.div<{
  durationValue: number
}>`
  margin-top: 5px;
  display: ${({ durationValue }) => (durationValue > 5 ? 'grid' : 'none')};
  grid-template-columns: 23% auto;
  grid-column-gap: 6px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    grid-template-columns: 15% auto;
  }
`

const StyledSplitItem3Left = styled.span`
  ${({ theme }) => theme.mixins.textSmall3}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.textNormal}
  }
`

const StyledSplitItem3Right = styled.span`
  ${({ theme }) => theme.mixins.textSmall2}
  color: #8c8c8c;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    align-self: end;
    ${({ theme }) => theme.mixins.textNormal}
  }
`

const StyledAgreeText = styled.div`
  margin-top: 10px;
  text-align: center;
  ${({ theme }) => theme.mixins.textSmall3}
  color: #A2A3AD;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    align-self: end;
    ${({ theme }) => theme.mixins.textSmall}
  }
`

const StyledConsultantAcceptLink = styled.a`
  text-decoration: none;
  color: #a2a3ad;
`

const StyledSubtitle2Text = styled.div`
  ${({ theme }) => theme.mixins.textNormal}
  margin-top: 19.96px;
  color: #5e5e5e;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H6}
  }
`

const StyledCommentText = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  margin-top: 32px;
`

export default ModalInstallment
