import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { useTranslation } from 'next-i18next'
import { apiPaymentChecker, api } from '../utils/http'
import { TCard } from './card'
import { notify, ToastIcon } from '../components/Toast'
import { ALERT_CLOSE_WAIT } from '../constants'
import { IError } from './error'
import { TPaymentsSystem } from './subscription'
// interceptor payment checker
apiPaymentChecker.interceptors.response.use(
  res => {
    const originalRequest = res.config
    if (res.data && !res.data.isFinished) {
      return new Promise(resolve => {
        setTimeout(() => resolve(apiPaymentChecker(originalRequest)), 3000)
      })
    }
    return res
  },
  error => {
    return Promise.reject(error)
  },
)

export interface IChecker {
  type: number
  system: TPaymentsSystem
  code: string
  status: number
  isFinished: boolean
  createdAt: string
  paidAt: string
  card: TCard | null
  amount: number
  currency: string
  canBeCancelled: boolean
  hasSubscription: boolean
  isFirst: boolean
}

const payment = {
  getPaymentCheck: (
    headers: { [key: string]: string },
    code: string,
  ): Promise<IChecker> => {
    return apiPaymentChecker
      .get(`/api/payments/${code}/check`, {
        headers,
      })
      .then(res => res.data)
  },
  postPaymentCancel: (
    headers: { [key: string]: string },
    code: string,
  ): Promise<AxiosResponse<IChecker>> => {
    return api.post(`/api/payments/${code}/cancel`, null, {
      headers,
    })
  },
}

export interface IPaymentCancelBody {
  headers: { [key: string]: string }
  code: string
}

export const usePaymentMutation = {
  usePostPaymentCancel: () => {
    const { t } = useTranslation()
    return useMutation<
      AxiosResponse<IChecker>,
      AxiosError<IError>,
      IPaymentCancelBody
    >(formData => payment.postPaymentCancel(formData.headers, formData.code), {
      onError: () => {
        notify({
          icon: ToastIcon.ERROR,
          autoClose: ALERT_CLOSE_WAIT,
          label: t('notify.paymentCancel.failed'),
        })
      },
    })
  },
}

export default payment
