import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import type { StylesConfig, CSSObjectWithLabel, Props } from 'react-select'
/**
 * context
 */
import { useAppDispatch, useAppState } from '../../context/app'
/**
 * ui
 */
import StyledContainer from '../Layout/Container'
import Icon from '../Icon'
import CircleButton, { CircleButtonStyle } from '../CircleButton'
import { TModal } from '../Modal'
import List from '../List'
import Avatar, { AvatarSize } from '../Avatar'
import Hide from '../Hide'
/**
 * hooks
 */
import useClickOutside from '../../hooks/useClickOutside'
import useLocales from '../../hooks/useLocales'

interface Style {
  style?: React.CSSProperties
}
export interface IHeader extends Style {
  hideCatalog?: boolean
  hideRightSide?: boolean
}

const {
  publicRuntimeConfig: { INTERNALIZATION },
} = getConfig()

type TOption = {
  label: string
  value: string
}

const Select = dynamic<Props<TOption, false>>(import('react-select'), {
  ssr: false,
})

const Header = React.forwardRef<HTMLHeadElement, IHeader>(
  ({ hideCatalog, hideRightSide, style }, ref) => {
    const { t, i18n } = useTranslation('components.header')
    const router = useRouter()
    const [isMenuOpen, setMenuBtn] = useState(false)
    const { modalShown } = useAppState()
    const dispatch = useAppDispatch()
    const { data: session } = useSession()
    const { onToggleLanguageClick } = useLocales()

    const [options] = useState<TOption[]>([
      { value: 'hy', label: 'Հայ' },
      { value: 'en', label: 'Eng' },
      { value: 'ru', label: 'Рус' },
    ])
    const ListRef = React.useRef<HTMLDivElement>(null)
    const AvatarRef = React.useRef<HTMLImageElement>(null)
    const AvatarMobileRef = React.useRef<HTMLImageElement>(null)
    const MenuRef = React.useRef<HTMLButtonElement>(null)
    useClickOutside([ListRef, AvatarRef, MenuRef, AvatarMobileRef], () => {
      setMenuBtn(false)
    })

    const setModal = useCallback(
      (): void =>
        dispatch({
          type: 'SET_MODAL',
          modalShown: !modalShown,
          modalType: TModal.LOGIN,
        }),
      [dispatch, modalShown],
    )

    const goToContact = useCallback(() => router.push('/contacts'), [router])

    const onClickMenu = useCallback(() => {
      setMenuBtn(!isMenuOpen)
    }, [isMenuOpen])

    const onClickMain = useCallback(() => router.push('/'), [router])

    const customStyles: StylesConfig<TOption, false> = {
      control: (styled, state) =>
        ({
          padding: '10.5px 10px',
          borderRadius: '10px 10px 0px 0px',
          display: 'flex',
          justifyContent: 'space-between',
          width: '95px',
          background: state.selectProps.menuIsOpen && '#E9E9E9',
        } as CSSObjectWithLabel),
      valueContainer: () => ({
        display: 'flex',
        alignItems: 'center',
      }),
      indicatorsContainer: () => ({}),
      indicatorSeparator: () => ({}),
      dropdownIndicator: (styled, state) =>
        ({
          display: 'flex',
          alignItems: 'center',
          transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
          color: state.selectProps.menuIsOpen ? 'black' : 'black',
        } as CSSObjectWithLabel),
      menu: styled => {
        return {
          ...styled,
          top: '38px',
          boxShadow: '0 4px 54px rgba(0, 0, 0, 0.05)',
          marginTop: 0,
          borderRadius: '0px 0px 10px 10px',
        }
      },
      menuList: () => ({
        '&:last-child': {
          borderRadius: '0px 0px 10px 10px',
        },
      }),
      option: () => ({
        color: '#191919',
        textAlign: 'center',
        padding: '7px 0',
        '&:hover': {
          background: '#E9E9E9',
        },
        margin: 0,
        '&:last-child': {
          borderRadius: '0px 0px 10px 10px',
        },
      }),
    }
    return (
      <StyledHeader ref={ref} style={style}>
        <StyledContainer>
          <StyledHeaderInner>
            {/* Brand */}
            <StyledHeaderBrand>
              <StyledHeaderLogo onClick={onClickMain}>
                <Icon name='logo' width='162' height='30' />
              </StyledHeaderLogo>
              {/* каталог */}
              {!hideCatalog ? (
                <Link href='/catalogue' passHref>
                  <StyledHeaderButton>
                    <StyledIcon>
                      <Icon name='category' width='20' height='20' />
                    </StyledIcon>
                    {t('catalogue')}
                  </StyledHeaderButton>
                </Link>
              ) : null}
            </StyledHeaderBrand>
            {/* Right side */}
            {!hideRightSide ? (
              <StyledHeaderNavContainer>
                <StyledHeaderNav>
                  {!session && (
                    <StyledHeaderNavList>
                      <StyledHeaderNavListItem onClick={goToContact}>
                        {t('contacts')}
                      </StyledHeaderNavListItem>
                      <StyledHeaderNavListItem onClick={setModal}>
                        {t('login')}
                      </StyledHeaderNavListItem>
                      <Hide hide={!JSON.parse(INTERNALIZATION) as boolean}>
                        <StyledHeaderNavListItem>
                          <Select
                            styles={customStyles}
                            isSearchable={false}
                            options={options.filter(
                              op => op.value !== i18n.language,
                            )}
                            value={options.find(op => {
                              return op.value === i18n.language
                            })}
                            onChange={option => {
                              option && onToggleLanguageClick(option.value)
                            }}
                          />
                        </StyledHeaderNavListItem>
                      </Hide>
                    </StyledHeaderNavList>
                  )}
                  {session && (
                    <Avatar
                      ref={AvatarRef}
                      checked={isMenuOpen}
                      size={AvatarSize.SMALL}
                      onClick={onClickMenu}
                    />
                  )}
                </StyledHeaderNav>
              </StyledHeaderNavContainer>
            ) : null}
            {/* для не авторизированного и мобила btn */}
            {!session && !hideRightSide && (
              <StyledHeaderUnauthorized>
                <StyledHeaderUnauthorizedLogin onClick={setModal}>
                  {t('login')}
                </StyledHeaderUnauthorizedLogin>

                <StyledHeaderCategoryButton>
                  <CircleButton
                    ref={MenuRef}
                    buttonStyle={CircleButtonStyle.OUTLINED}
                    icon='category'
                    iconSize='20'
                    clicked={isMenuOpen}
                    onClick={onClickMenu}
                  />
                </StyledHeaderCategoryButton>
              </StyledHeaderUnauthorized>
            )}
            {/* для авторизированног mobile size */}
            {session && !hideRightSide && (
              <StyledHeaderAuthorized>
                <Link scroll={true} passHref href='/catalogue'>
                  <CircleButton
                    buttonStyle={CircleButtonStyle.OUTLINED}
                    icon='category'
                    iconSize='20'
                  />
                </Link>
                <StyledAvatarWrapper>
                  <Avatar
                    ref={AvatarMobileRef}
                    checked={isMenuOpen}
                    size={AvatarSize.SMALL}
                    onClick={onClickMenu}
                  />
                </StyledAvatarWrapper>
              </StyledHeaderAuthorized>
            )}
            <List clicked={isMenuOpen} ref={ListRef} />
          </StyledHeaderInner>
        </StyledContainer>
      </StyledHeader>
    )
  },
)

Header.displayName = 'Header'
export default Header

const StyledIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`

const StyledLink = styled.a`
  text-decoration: none;
  ${({ theme }) => theme.mixins.textButton};
  color: ${({ theme }) => theme.colors.black};
  background: #fff;
  height: 45px;
  border-radius: 50px;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('center')}
  border: 1px solid ${({ theme }) => theme.colors.greySecondary};
  position: relative;
  &:hover {
    border: solid 1px ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    background-color: #ffffff;

    & path {
      transition: all 0.2s linear;
      stroke: ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledHeader = styled.header<Style>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 999;
  margin: 0 auto;
  background-color: #fff;
`
const StyledHeaderInner = styled.div`
  padding: 16px 0;
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  ${({ theme }) => theme.mixins.justifyContent('space-between')}
`

const StyledHeaderBrand = styled.div`
  ${({ theme }) => theme.mixins.justifyContent('start')}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.flexbox()}
    ${({ theme }) => theme.mixins.alignItems('center')}
  }
`

const StyledHeaderLogo = styled.div`
  cursor: pointer;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-right: 40px;
  }
`
const StyledHeaderButton = styled(StyledLink)`
  display: none;
  padding: 0 28px 0 19px;
  cursor: pointer;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.flexbox()}
  }
`
const StyledHeaderNavContainer = styled.nav`
  display: none;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: block;
  }
`

const StyledHeaderNav = styled.nav``

const StyledHeaderNavList = styled.ul`
  ${({ theme }) => theme.mixins.flexbox()}
`

const StyledHeaderNavListItem = styled.li`
  display: flex;
  align-items: center;
  color: black;
  cursor: pointer;
  ${({ theme }) => theme.mixins.textSmall}
  ${({ theme }) => theme.mixins.transition('all 0.5s linear')}
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  & + & {
    margin-left: 53px;
  }
`
const StyledHeaderUnauthorized = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: none;
  }
`

const StyledHeaderUnauthorizedLogin = styled.div`
  color: black;
  cursor: pointer;
  ${({ theme }) => theme.mixins.textSmall}
  ${({ theme }) => theme.mixins.transition('all 0.5s linear')}
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  margin-right: 19px;
`

const StyledHeaderCategoryButton = styled.div`
  cursor: pointer;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: none;
  }
`
const StyledHeaderAuthorized = styled.div`
  ${({ theme }) => theme.mixins.flexbox()}
  ${({ theme }) => theme.mixins.alignItems('center')}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: none;
  }
`

const StyledAvatarWrapper = styled.div`
  margin-left: 12px;
`
