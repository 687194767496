import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { api } from '../utils/http'
import { useMutation, useQuery } from 'react-query'
import { AxiosResponse, AxiosError } from 'axios'
import { useLocalStorage } from 'usehooks-ts'
import { ISubscriptions } from './subscription'
import { IError } from './error'
import { notify, ToastIcon } from '../components/Toast'
import { ALERT_CLOSE_WAIT } from '../constants'
import { ILocalStorage } from '../hooks/useLocalStorage/useLocalStorage'
import Metrika from '../utils/Metrika'

export type TMagicLinkBody = {
  mtoken: string
}

export type TMagicLinkResponse = {
  token: string
}

export type TRegistrationBody = {
  email: string
  recaptcha: string
  mailConsent?: boolean
  utm?: string // from lading page
}

type TSecureRequestBody = {
  email: string
  recaptcha: string
}

type TRegistrationResWithProduct = {
  success: boolean
  token: string
  subscription: ISubscriptions
  errors?: {
    email?: {
      errors: string[]
    }
  }
}

export type TChangePassword = {
  current: string
  new: {
    first: string
    second: string
  }
}
type TChangePassword2 = {
  headers: { [key: string]: string }
  current: string
  new: {
    first: string
    second: string
  }
}
type TPostResetPasswordCode = {
  code: string
  first: string
  second: string
}

type TVerifyPhoneBody = {
  headers: { [key: string]: string }
  phone: string
  code: string
}

const secureApi = {
  postRegistration: (
    data: TRegistrationBody,
  ): Promise<AxiosResponse<TRegistrationResWithProduct>> => {
    const { email, recaptcha, mailConsent } = data
    const url = data.utm
      ? '/api/secure/registration' + `${data.utm}`
      : '/api/secure/registration'
    return api.post(url, {
      email,
      recaptcha,
      mailConsent,
    })
  },
  postConfirmEmail: async () => {},
  postResetPasswordRequest: (email: string, recaptcha: string) => {
    return api.post<{ success: boolean }>(
      '/api/secure/reset_password/request',
      {
        email,
        recaptcha,
      },
    )
  },
  getResetPasswordCheck: (code: string) => {
    return api.get<{ success: boolean }>(
      `/api/secure/reset_password/${code}/check`,
    )
  },
  postResetPasswordCode: (code: string, first: string, second: string) => {
    return api.post<{ success: boolean }>(
      `/api/secure/reset_password/${code}`,
      {
        new: {
          first,
          second,
        },
      },
    )
  },
  postChangePassword: (
    headers: { [key: string]: string },
    body: TChangePassword,
  ) => {
    return api.post<{ success: boolean }>('/api/secure/change_password', body, {
      headers,
    })
  },
  getLogout: async () => {},
  getVerifyPhoneRequest: (payload: {
    [key: string]: string
  }): Promise<AxiosResponse<{ success: boolean }>> => {
    return api.get(`/api/secure/verify_phone_request`, {
      headers: payload,
    })
  },
  postVerifyPhone: (
    payload: Omit<TVerifyPhoneBody, 'phone'>,
  ): Promise<AxiosResponse<{ success: boolean }>> => {
    return api.post(
      `/api/secure/verify_phone`,
      { code: payload.code },
      { headers: payload.headers },
    )
  },
  postMagicLink: (
    body: TMagicLinkBody,
  ): Promise<AxiosResponse<TMagicLinkResponse>> => {
    return api.post(`/api/secure/otp/login`, body)
  },
}

export const useSecureQuery = {
  useGetResetPasswordCheck: (code: string) => {
    return useQuery<AxiosResponse<{ success: boolean }>, AxiosError<IError>>(
      ['getResetPasswordCheck'],
      () => secureApi.getResetPasswordCheck(code),
      {
        enabled: !!code,
      },
    )
  },
  useGetVerifyPhoneRequest: (
    payload: { [key: string]: string },
    onSuccess: (response: AxiosResponse<{ success: boolean }>) => void,
    onError: (err: AxiosError<IError>) => void,
  ) =>
    useQuery<AxiosResponse<{ success: boolean }>, AxiosError<IError>>(
      ['getVerifyPhoneRequest'],
      () => secureApi.getVerifyPhoneRequest(payload),
      {
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: response => {
          onSuccess(response)
        },
        onError: err => {
          onError(err)
        },
      },
    ),
}

export const useSecureMutation = {
  usePostRegistration: () => {
    const [metrika] = useState(() => new Metrika())
    const [local] = useLocalStorage<ILocalStorage>('willskill', {
      cookieAgree: false,
      utm: '',
    })
    return useMutation<
      AxiosResponse<TRegistrationResWithProduct>,
      AxiosError<IError>,
      TRegistrationBody
    >(
      formData => {
        if (local.utm) {
          formData.utm = decodeURIComponent(local.utm)
        }
        return secureApi.postRegistration(formData)
      },
      {
        onSuccess: () => {
          metrika
            .willskillYandex({
              methos: 'reachGoal',
              targetNames: 'new_reg',
            })
            .offerrmYandex({
              methos: 'reachGoal',
              targetNames: 'new_reg',
            })
            .offerrmVk({
              methos: 'reachGoal',
              targetNames: 'new_reg',
            })
        },
      },
    )
  },
  usePostRequest: () =>
    useMutation<
      AxiosResponse<{ success: boolean }>,
      AxiosError<IError>,
      TSecureRequestBody
    >(formData =>
      secureApi.postResetPasswordRequest(formData.email, formData.recaptcha),
    ),
  usePostChangePassword: () => {
    const { t } = useTranslation()
    return useMutation<
      AxiosResponse<{ success: boolean }>,
      AxiosError<IError>,
      TChangePassword2
    >(
      formData =>
        secureApi.postChangePassword(formData.headers, {
          current: formData.current,
          new: formData.new,
        }),
      {
        onSuccess: async () => {
          notify({
            icon: ToastIcon.SUCCESS,
            autoClose: ALERT_CLOSE_WAIT,
            label: t('validations.changePassword.success'),
          })
        },
      },
    )
  },
  usePostResetPasswordCode: () =>
    useMutation((formData: TPostResetPasswordCode) =>
      secureApi.postResetPasswordCode(
        formData.code,
        formData.first,
        formData.second,
      ),
    ),
  usePostVerifyPhone: () =>
    useMutation<
      AxiosResponse<{ success: boolean }>,
      AxiosError<IError>,
      Omit<TVerifyPhoneBody, 'phone'>
    >(formData => secureApi.postVerifyPhone(formData)),
  usePostMagicLink: () => {
    return useMutation<
      AxiosResponse<TMagicLinkResponse>,
      AxiosError<IError>,
      TMagicLinkBody
    >(body => secureApi.postMagicLink(body))
  },
}

export default secureApi
