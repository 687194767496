import React from 'react'
import styled, { css } from 'styled-components'

import Icon from '../Icon'
import { IconPackType } from '../Icon/icons'

export enum CircleButtonStyle {
  ARROW = 'arrow',
  ADD = 'add',
  OUTLINED = 'outlined',
  ICON = 'icon',
  LARGE = 'large',
}

interface Style {
  buttonStyle: CircleButtonStyle
  disabled?: boolean
  clicked?: boolean
  shadow?: string
}

interface Props extends Style {
  icon: IconPackType
  onClick?: (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent) => void
  iconSize?: string
}

const CircleButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    { buttonStyle, disabled, onClick, icon, iconSize, clicked = false, shadow },
    ref,
  ) => {
    return (
      <StyledContainer
        buttonStyle={buttonStyle}
        disabled={disabled}
        onClick={onClick}
        clicked={clicked}
        ref={ref}
        shadow={shadow}
        aria-label={buttonStyle}
      >
        <Icon name={icon} width={iconSize} height={iconSize} />
      </StyledContainer>
    )
  },
)

const StyledContainer = styled.button<Style>`
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  ${({ shadow }) => {
    if (shadow) {
      return css`
        box-shadow: ${shadow};
      `
    }
  }}

  ${({ buttonStyle, clicked }) => {
    switch (buttonStyle) {
      case CircleButtonStyle.ARROW:
        return css`
          width: 45px;
          height: 45px;
          background-color: ${({ theme }) => theme.colors.primaryLight};

          &:hover {
            box-shadow: 0 14px 24px rgba(24, 168, 102, 0.4);
          }

          &:disabled {
            border: ${({ theme }) => theme.colors.grey};
            background-color: ${({ theme }) => theme.colors.grey};
            box-shadow: none;
          }

          svg {
            width: 20px;
            height: 20px;

            path {
              stroke: ${({ theme }) => theme.colors.primary};
            }
          }
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            width: 60px;
            height: 60px;
            background-color: ${({ theme }) => theme.colors.primary};
            svg {
              path {
                stroke: #fff;
              }
            }
          }
        `

      case CircleButtonStyle.ADD:
        if (clicked) {
          return css`
            width: 45px;
            height: 45px;
            background-color: ${({ theme }) => theme.colors.primary};
            transition: all 0.5s linear;
            path {
              stroke: #ffffff;
            }
            svg {
              width: 20px;
              height: 20px;
              transform: rotate(45deg);
              transition: all 0.5s linear;
            }
          `
        }
        return css`
          width: 45px;
          height: 45px;
          background-color: ${({ theme }) => theme.colors.primaryLight};
          transition: all 0.5s linear;

          svg {
            width: 20px;
            height: 20px;
            transition: all 0.5s linear;
          }
        `

      case CircleButtonStyle.OUTLINED:
        return css`
          width: 45px;
          height: 45px;
          background-color: #ffffff;
          border: 1px solid ${({ theme }) => theme.colors.greySecondary};

          svg {
            width: 20px;
            height: 20px;
          }

          &:hover {
            border: 1px solid ${({ theme }) => theme.colors.primary};

            path {
              stroke: ${({ theme }) => theme.colors.primary};
            }
          }
          //
          // &:disabled {
          //   border: 1px solid ${({ theme }) => theme.colors.grey};
          //
          //   path {
          //     stroke: ${({ theme }) => theme.colors.grey};
          //   }
          // }
        `

      case CircleButtonStyle.ICON:
        return css`
          width: 45px;
          height: 45px;

          svg {
            width: 20px;
            height: 20px;
          }

          &:hover {
            path {
              stroke: ${({ theme }) => theme.colors.primary};
            }
          }
        `

      case CircleButtonStyle.LARGE:
        return css`
          width: 72px;
          height: 72px;
          background-color: #ffffff;

          svg {
            width: 24px;
            height: 24px;
          }
        `
    }
  }}
`
CircleButton.displayName = 'CircleButton'
export default CircleButton
