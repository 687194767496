import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useQueryClient } from 'react-query'
/**
 * context
 */
import { useAppDispatch, useAppState } from '../../context/app'
/**
 * ui
 */
import Icon from '../Icon'
import ModalLogin from './ModalLogin'
import ModalRegistration from './ModalRegistration'
import ModalForgot from './ModalForgot'
import ModalUnsubscribe from './ModalUnsubscribe'
import ModalNewPassword from './ModalNewPassword'
import ModalStop from './ModalStop'
import ModalCardDisable from './ModalCardDisable'
import ModalPayment from './ModalPayment'
import ModalCourseAgreePayment from './ModalCourseAgreePayment'
import ModalCardAdd from './ModalCardAdd'
import ModalCancelPayment from './ModalCancelPayment'
import ModalStopFinal from './ModalStopFinal'
import ModalStopMiddle from './ModalStopMiddle'
import ModalFinishCourse from './ModalFinishCourse'
import ModalFillPersonalDetails from './ModalFillPersonalDetails'
import ModalWhatIsYourName from './ModalWhatIsYourName'
import ModalCancelPaymentWebinar from './ModalCancelPaymentWebinar'
import ModalSelectInitialPayType from './ModalSelectInitialPayType'
import ModalSelectPaymentsType from './ModalSelectPaymentsType'
import ModalCancelInstallmentPayment from './ModalCancelInstallmentPayment'
import ModalSelectContinueOrInstallment from './ModalSelectContinueOrInstallment'
import ModalSelectCertification from './ModalSelectCertification'
import ModalInstallment from './ModalInstallment'
import ModalTinkoff from './ModalTinkoff'
import ModalForm from './ModalForm'
/**
 * hooks
 */
import useClickOutside from '../../hooks/useClickOutside'

export enum TModal {
  REGISTRATION = 'registration',
  UNSUBSCRIBE = 'unsubscribe',
  STOP = 'stop',
  STOP_MIDDLE = 'stopMiddle',
  STOP_FINAL = 'stopFinal',
  LOGIN = 'login',
  NEW = 'newPassword',
  FORGOT = 'forgot',
  CARD = 'card',
  PAYMENT = 'payment',
  INSTALLMENT = 'installment',
  AGREEMENT = 'agreePayment',
  ADDCARD = 'addCard',
  CANCEL_PAYMENT = 'cancelPayment',
  CANCEL_PAYMENT_WEBINAR = 'cancelPaymentWebinar',
  CANCEL_INSTALLMENT_PAYMENT = 'cancelInstallmentPayment',
  FINISH_COURSE = 'finishCourse',
  FILL_PERSONAL_DETAIL = 'fillPersonalDetails',
  WHAT_IS_YOUR_NAME = 'whatIsYourName',
  SELECT_INITIAL_PAY_TYPE = 'selectInitialPayType',
  SELECT_PAYMENTS_TYPE = 'selectPaymentsType',
  SELECT_CONTINUE_OR_INSTALLMENT_PAYMENT = 'selectContinueOrInstallment',
  SELECT_CERTIFICATION = 'selectCertification',
  TINKOFF = 'tinkoff',
  FORM = 'form',
  BLANK = 'blank',
}

// const ModalOption = {
//   registration: <ModalRegistration />,
//   unsubscribe: <ModalUnsubscribe />,
//   stop: <ModalStop />,
//   login: <ModalLogin />,
//   newPassword: <ModalNewPassword />,
//   forgot: <ModalForgot />,
//   card: <ModalCardDisable />,
//   payment: <ModalPayment />,
//   agreePayment: <ModalCourseAgreePayment />,
//   addCard: <ModalCardAdd />,
//   cancelPayment: <ModalCancelPayment />,
//   blank: '',
// }

const Modal = () => {
  const router = useRouter()
  const { modalShown, modalType } = useAppState()
  const dispatch = useAppDispatch()
  const [blockClickOutside, setBlockClickOutside] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const setModal = async () => {
    // для /payments/form
    if (
      modalType === TModal.LOGIN &&
      router.pathname === '/payments/form' &&
      router.query.slug
    ) {
      dispatch({
        type: 'SET_MODAL',
        modalShown: !modalShown,
        modalType: TModal.BLANK,
      })
      setBlockClickOutside(false)
      return router.push(`/product/${router.query.slug}`)
    }
    if (modalType === TModal.STOP) {
      dispatch({
        type: 'SET_MODAL',
        modalShown: true,
        modalType: TModal.STOP_MIDDLE,
      })
    } else if (modalType === TModal.INSTALLMENT) {
      dispatch({
        type: 'SET_MODAL',
        modalShown: false,
        modalType: TModal.BLANK,
      })
      dispatch({
        type: 'SET_SLUG',
        slug: '',
      })
      // router.reload()
      await queryClient.invalidateQueries(['getSubscriptions', ''])
    } else {
      dispatch({
        type: 'SET_MODAL',
        modalShown: !modalShown,
        modalType: TModal.BLANK,
      })
      setBlockClickOutside(false)
    }
  }
  const modalRef = React.useRef<HTMLDivElement>(null)
  const formRef = React.useRef<HTMLFormElement>(null)

  useClickOutside([modalRef, formRef], () => {
    setModal()
  })
  // для блокирования clickOutside
  useEffect(() => {
    if (modalType === 'newPassword') {
      setBlockClickOutside(true)
    }
    if (modalType === 'tinkoff') {
      setBlockClickOutside(true)
    }
  }, [modalType])

  if (!modalShown) {
    return null
  }

  return (
    <StyledWrapper>
      <StyledModalBody ref={blockClickOutside ? null : modalRef}>
        <StyledCloseIconWrapper onClick={setModal}>
          <Icon name='close' width='24' height='24' />
        </StyledCloseIconWrapper>
        {/*{ModalOption[modalType]}*/}
        {modalType === 'registration' && <ModalRegistration />}
        {modalType === 'unsubscribe' && <ModalUnsubscribe />}
        {modalType === 'stop' && <ModalStop />}
        {modalType === 'stopMiddle' && <ModalStopMiddle />}
        {modalType === 'stopFinal' && <ModalStopFinal />}
        {modalType === 'login' && <ModalLogin />}
        {modalType === 'newPassword' && (
          <ModalNewPassword
            ref={formRef}
            blockClickOutside={blockClickOutside}
          />
        )}
        {modalType === 'tinkoff' && (
          <ModalTinkoff ref={modalRef} blockClickOutside={blockClickOutside} />
        )}
        {modalType === 'forgot' && <ModalForgot />}
        {modalType === 'card' && <ModalCardDisable />}
        {modalType === 'payment' && <ModalPayment />}
        {modalType === 'agreePayment' && <ModalCourseAgreePayment />}
        {modalType === 'addCard' && <ModalCardAdd />}
        {modalType === 'cancelPayment' && <ModalCancelPayment />}
        {modalType === 'cancelPaymentWebinar' && <ModalCancelPaymentWebinar />}
        {modalType === 'finishCourse' && <ModalFinishCourse />}
        {modalType === 'fillPersonalDetails' && <ModalFillPersonalDetails />}
        {modalType === 'whatIsYourName' && <ModalWhatIsYourName />}
        {modalType === 'selectInitialPayType' && <ModalSelectInitialPayType />}
        {modalType === 'selectPaymentsType' && <ModalSelectPaymentsType />}
        {modalType === 'installment' && <ModalInstallment />}
        {modalType === 'cancelInstallmentPayment' && (
          <ModalCancelInstallmentPayment />
        )}
        {modalType === 'selectContinueOrInstallment' && (
          <ModalSelectContinueOrInstallment />
        )}
        {modalType === 'selectCertification' && <ModalSelectCertification />}
        {modalType === 'form' && <ModalForm />}
        {modalType === 'blank' && ''}
      </StyledModalBody>
    </StyledWrapper>
  )
}

export default Modal

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;

  width: 100vw; /* viewport width */
  height: 100vh; /* viewport height */
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgba(237, 242, 237, 0.6);
  backdrop-filter: blur(3px);
  ${({ theme }) => theme.mixins.flexbox()}
  z-index: 9990;
`

const StyledModalBody = styled.div`
  margin: auto;
  position: relative;
  overflow: hidden;
  /* background: #ffffff;
  box-shadow: 0 4px 54px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 35px 24px;
  width: 100%;
  max-width: 100%;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding: 68px 97px;
    max-width: 555px;
  } */
`

const StyledCloseIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 8px;
  cursor: pointer;
  z-index: 100;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    top: 32.76px;
    right: 27.76px;
  }
`
