import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useRouter } from 'next/router'
import { Trans, useTranslation } from 'next-i18next'
import { motion } from 'framer-motion'
import getConfig from 'next/config'
import { useLocalStorage } from 'usehooks-ts'
/**
 * ui
 */
import Tag, { TagSize, TagType } from '../Tag'
import Icon from '../Icon'
import Input, { InputStyle } from '../Input'
import Button, { ButtonSize, ButtonStyle } from '../Button'
import { TModal } from '../Modal'
// import { notify, ToastIcon } from '../Toast'
/**
 * images
 */
import BRAND from '../../../public/assets/images/brand-stroke.svg'
import BRANDLOGO from '../../../public/assets/images/brand.svg'
/**
 * api
 */
import { Module, TCurrency } from '../../api/product'
import {
  // ICurrentPayment,
  // useSubscriptions,
  ISubscriptions,
} from '../../api/subscription'
/*
 * utils and hooks
 */
import useClientSide from '../../hooks/useClientSide'
import useCustomForm, {
  ILocalStorageSelectedPayments,
} from '../../hooks/useForm'
import { TType } from '../../hooks/useForm/useForm'
import usePayments from '../../hooks/usePayments'
import { IHandleAccessType } from '../../hooks/usePayments/usePayments'
// import useHeaders from '../../hooks/useHeaders'
/**
 * constants
 */
import {
  OFERTA,
  PERSONAL_DATA,
  PRIVACY_POLICY,
  // ALERT_CLOSE_WAIT,
} from '../../constants'
import { navigateCourse } from '../../constants/router'
/**
 * context
 */
import { useAppDispatch, useAppState } from '../../context/app'

const {
  publicRuntimeConfig: { ENV_NAME },
} = getConfig()

// const spring = {
//   type: 'spring',
//   stiffness: 700,
//   damping: 50,
// }

export enum FormType {
  FIRST = 'first', // product page top (deprecated)
  SECOND = 'second', // product page bottom
  THIRD = 'third', // для страницы /[category]/[productSlug]/order
  FOURTH = 'fourth', // для страницы/[category]/[productSlug]/order-b
  ORDER_SECOND = 'orderSecond', // // для страницы/[category]/[productSlug]/ordersecond
  ORDER_ALL = 'all',
  MODAL = 'modal',
}

interface Props {
  slug: string
  category: string
  productName: string
  modulesCount: number
  lessonsCount: number
  fullPrice: number
  fullPayDiscountPercent: number
  initialAmounts: {
    [type: string]: number
  } | null
  formType: FormType
  subscription?: ISubscriptions
  accessType: number
  currency: string | null
  hideRadio?: boolean
  nextBlock: {
    days: string
    price: string
  } | null
  title?: string
  testTitle?: string
  version?: string
  modules?: Module[]
  subscriptionType?: TType
  installment?: {
    amount: number
    systems: string[]
  }
}

const Form = React.forwardRef<HTMLFormElement, Props>(
  (
    {
      slug,
      category,
      productName,
      modulesCount,
      lessonsCount,
      fullPrice,
      initialAmounts,
      fullPayDiscountPercent,
      formType,
      subscription,
      accessType,
      hideRadio,
      nextBlock,
      title,
      testTitle,
      version,
      modules,
      subscriptionType,
      installment,
      currency,
    },
    ref,
  ) => {
    // const { headers } = useHeaders()
    const [local, setLocal] = useLocalStorage<ILocalStorageSelectedPayments>(
      'willskill_selectedPayments',
      {},
    )
    // const { data: subscriptionsData } =
    //   useSubscriptions.useGetSubscriptions(headers)
    const dispatch = useAppDispatch()
    const { t } = useTranslation('common')
    const { t: tc } = useTranslation('components.form')
    const {
      formState: { isValid, errors, isSubmitting },
      register,
      watch,
      handler,
      isOrderCoursePage,
      status,
      setValue,
    } = useCustomForm({ slug, accessType, subscriptionType })
    const { handleCards } = usePayments()
    const { internalization } = useAppState()
    const router = useRouter()
    const isClientSide = useClientSide()
    const [timer, setTimer] = React.useState({
      minutes: 15,
      seconds: 60,
    })
    useEffect(() => {
      if (
        (formType === FormType.THIRD || formType === FormType.FOURTH) &&
        isClientSide
      ) {
        const interval = setInterval(() => {
          if (timer.seconds > 1) {
            setTimer(prev => ({ ...prev, seconds: timer.seconds - 1 }))
          }
          if (timer.seconds === 1) {
            setTimer(prev => ({
              ...prev,
              seconds: 60,
              minutes: timer.minutes - 1,
            }))
          }

          if (timer.seconds <= 1 && timer.minutes <= 0) {
            setTimer(prev => ({
              ...prev,
              seconds: 60,
              minutes: 15,
            }))
          }
        }, 1000)
        return () => clearInterval(interval)
      }
    }, [formType, isClientSide, timer.seconds, timer.minutes])
    // watch value in react hook form
    const subscriptionTypeValue = watch('subscriptionType')

    const checkContinue = () => {
      if (
        subscription &&
        subscription.status === 4 &&
        (subscription.stopReason === 2 ||
          subscription.stopReason === 1 ||
          subscription.stopReason === 7)
      ) {
        return true
      }
      return false
    }
    const checkPay = () => {
      if (
        subscription &&
        !subscription.canBeSurcharged &&
        subscription.status === 1 &&
        subscription.accessType === 1
      ) {
        return true
      }
      return false
    }
    const checkBuyAllModules = () => {
      if (subscription && subscription.canBeSurcharged) {
        return true
      }
      return false
    }
    const handlePreviousPayments = (data: ILocalStorageSelectedPayments) => {
      if (data[slug] && data[slug].subscriptionType === subscriptionTypeValue) {
        if (
          subscriptionTypeValue !== 'installment' &&
          subscription &&
          !subscription.currentPayment
        ) {
          handleCards({ slug })
        }

        if (
          subscriptionTypeValue !== 'installment' &&
          subscription &&
          subscription.currentPayment
        ) {
          handleCards({
            slug,
            body: {
              card: null,
              capture: true,
              cancelCurrent: true,
              type: subscriptionTypeValue,
            },
          })
        }

        if (
          subscriptionTypeValue === 'installment' &&
          subscription &&
          !subscription.currentPayment
        ) {
          dispatch({
            type: 'SET_MODAL',
            modalShown: true,
            modalType: TModal.INSTALLMENT,
          })
        }

        if (
          subscriptionTypeValue === 'installment' &&
          subscription &&
          subscription.currentPayment
        ) {
          dispatch({
            type: 'SET_CANCEL_CURRENT',
            cancelCurrent: true,
          })
          dispatch({
            type: 'SET_MODAL',
            modalShown: true,
            modalType: TModal.INSTALLMENT,
          })
        }
      }
      if (data[slug] && data[slug].subscriptionType !== subscriptionTypeValue) {
        setLocal({
          ...local,
          [slug]: {
            subscriptionType: subscriptionTypeValue,
          },
        })
        if (subscriptionTypeValue !== 'installment') {
          handleCards({
            slug,
            body: {
              card: null,
              capture: true,
              cancelCurrent: true,
              type: subscriptionTypeValue,
            },
          })
        }

        if (subscriptionTypeValue === 'installment') {
          dispatch({
            type: 'SET_CANCEL_CURRENT',
            cancelCurrent: true,
          })
          dispatch({
            type: 'SET_MODAL',
            modalShown: true,
            modalType: TModal.INSTALLMENT,
          })
        }
      }
    }
    const getCurrency = (currency: string) => {
      switch (currency) {
        case 'RUB':
          return TCurrency.RUB_SYMBOL
          break
        case 'AMD':
          return TCurrency.AMD_SYMBOL
          break
        default:
          return TCurrency.RUB_SYMBOL
      }
    }
    const onHandlePaymentAll = ({
      slug,
    }: Pick<IHandleAccessType, 'slug' | 'token'>) => {
      // для .com
      if (internalization) {
        dispatch({
          type: 'SET_MODAL',
          modalShown: true,
          modalType: TModal.AGREEMENT,
        })
      }
      // для .ru
      if (!internalization) {
        setLocal(() => ({
          ...local,
          [slug]: {
            subscriptionType: subscriptionTypeValue,
          },
        }))
        handlePreviousPayments({
          ...local,
          [slug]: {
            subscriptionType: subscriptionTypeValue,
          },
        })
      }

      dispatch({
        type: 'SET_SLUG',
        slug: slug,
      })
    }
    // const handleActualSubscription = () => {
    //   // Checks что платная подписка есть или нет
    //   const checkActualSubscription = (subscription: ISubscriptions) =>
    //     subscription.accessType === 1 && subscription.status === 2
    //   const canProceed = subscriptionsData
    //     ? subscriptionsData.some(checkActualSubscription)
    //     : false
    //   return canProceed
    // }

    return (
      <StyledCourseCheckout
        onSubmit={e => {
          e.preventDefault()
          dispatch({
            type: 'SET_SLUG',
            slug,
          })
          // if (handleActualSubscription()) {
          //   notify({
          //     icon: ToastIcon.ERROR,
          //     autoClose: ALERT_CLOSE_WAIT,
          //     label: t('notify.actualSubscription'),
          //   })
          //   return
          // }
          if (checkPay()) {
            handlePreviousPayments(local)
          }

          if (checkContinue()) {
            return router.push(navigateCourse)
          }
          if (checkBuyAllModules() && subscription) {
            onHandlePaymentAll({
              slug,
            })
          }
          if (!checkContinue() && !checkPay() && !checkBuyAllModules()) {
            handler()
          }
        }}
        formType={formType}
        ref={isClientSide ? ref : null}
      >
        <StyledCourseCheckoutInner formType={formType}>
          {formType === FormType.SECOND && (
            <StyledCourseCheckoutCategory>
              <Tag
                label={category}
                tagType={TagType.LIGHT}
                size={TagSize.PADDING}
              />
            </StyledCourseCheckoutCategory>
          )}

          {!version && (
            <StyledCourseCheckoutTitle formType={formType}>
              {productName}
            </StyledCourseCheckoutTitle>
          )}
          {version && (
            <StyledCourseCheckoutTitleVersion formType={formType}>
              {productName}
            </StyledCourseCheckoutTitleVersion>
          )}
          {title &&
            (formType === FormType.THIRD ||
              formType === FormType.FOURTH ||
              formType === FormType.ORDER_SECOND ||
              formType === FormType.ORDER_ALL ||
              formType === FormType.MODAL) && (
              <StyledCourseCheckoutTitleForOrder formType={formType}>
                {tc('onlineCourse')} {title}
              </StyledCourseCheckoutTitleForOrder>
            )}
          {testTitle &&
            (formType === FormType.THIRD ||
              formType === FormType.FOURTH ||
              formType === FormType.ORDER_SECOND) && (
              <StyledCourseCheckoutTitleForOrder formType={formType}>
                {testTitle}
              </StyledCourseCheckoutTitleForOrder>
            )}
          {/* count down for /order */}
          {formType === FormType.THIRD && (
            <StyledCourseCheckoutTimerInForm>
              <StyledCourseCheckoutTimerText>
                {tc('promotionEnds')}
              </StyledCourseCheckoutTimerText>
              <StyledCourseCheckoutTimerTotalPrice>
                {tc('price', { val: fullPrice / 100 })}
              </StyledCourseCheckoutTimerTotalPrice>
              <StyledCourseCheckoutTimerCount>
                {timer.minutes} : {timer.seconds}
              </StyledCourseCheckoutTimerCount>
              <StyledCourseCheckoutTimerRuble>
                {isOrderCoursePage && initialAmounts
                  ? tc('price', { val: initialAmounts.course / 100 })
                  : tc('oneRub')}
              </StyledCourseCheckoutTimerRuble>
              {isOrderCoursePage && (
                <StyledCourseCheckoutTimerSale>
                  {tc('discount')}
                </StyledCourseCheckoutTimerSale>
              )}
              {isOrderCoursePage && (
                <StyledCourseCheckoutTimerSaleTag>
                  <Tag
                    tagType={TagType.ERROR}
                    label={`-${fullPayDiscountPercent}%`}
                    size={TagSize.PADDING}
                  />
                </StyledCourseCheckoutTimerSaleTag>
              )}
            </StyledCourseCheckoutTimerInForm>
          )}
          {/* /order - b */}
          {formType === FormType.FOURTH && (
            <StyledCourseCheckoutTimerInFormB>
              <StyledCourseCheckoutDescriptionB>
                {!version && tc('accessToFirstWeek')}
                {version &&
                  version === 'c' &&
                  tc('accessToFirstWeekOfTraining')}
                {version &&
                  version === 'd' &&
                  modules &&
                  tc('accessToFirstLearningLessons', {
                    lessons: modules[0].lessons.length ?? '0',
                  })}
              </StyledCourseCheckoutDescriptionB>
              <StyledCourseCheckoutTimerTextB>
                {tc('promotionEnds')} {version && 'через'}
              </StyledCourseCheckoutTimerTextB>
              <StyledCourseCheckoutTimerCountB>
                {timer.minutes} : {timer.seconds}
              </StyledCourseCheckoutTimerCountB>
              <StyledCourseCheckoutTimerRubleB>
                {tc('oneRub')}
              </StyledCourseCheckoutTimerRubleB>
            </StyledCourseCheckoutTimerInFormB>
          )}
          {/* /ordersecond */}
          {formType === FormType.ORDER_SECOND && nextBlock && (
            <StyledCourseCheckoutSecond>
              <StyledCourseCheckoutSecondPay>
                {tc('priceRub', { val: Number(nextBlock.price) / 100 })}
              </StyledCourseCheckoutSecondPay>
              <StyledCourseCheckoutSecondDesc>
                {tc('accessToSecondBlock')}
              </StyledCourseCheckoutSecondDesc>
            </StyledCourseCheckoutSecond>
          )}
          {version && (
            <StyledCoursedCheckoutResult>
              {tc('onCourseYouWillReceive')}
            </StyledCoursedCheckoutResult>
          )}
          {/* icons уроков и недель */}
          <StyledCoursedCheckoutFeatures formType={formType}>
            <StyledCoursedCheckoutFeaturesItem>
              <StyledCoursedCheckoutFeaturesItemImage>
                <Icon name='book' />
              </StyledCoursedCheckoutFeaturesItemImage>
              {t('lessons', { count: lessonsCount })}
            </StyledCoursedCheckoutFeaturesItem>
            <StyledCoursedCheckoutFeaturesItem>
              <StyledCoursedCheckoutFeaturesItemImage>
                <Icon name='calendar' />
              </StyledCoursedCheckoutFeaturesItemImage>
              {t('weeks', { count: modulesCount })}
            </StyledCoursedCheckoutFeaturesItem>
          </StyledCoursedCheckoutFeatures>
          {/* полная стоимость */}
          {!subscription && !fullPayDiscountPercent && (
            <StyledFullPriceText>
              Стоимость курса{' '}
              {t('intlNumber', {
                val: fullPrice / 100,
              })}{' '}
              руб.
            </StyledFullPriceText>
          )}
          {!subscription &&
            fullPayDiscountPercent &&
            initialAmounts &&
            formType !== FormType.THIRD &&
            formType !== FormType.FOURTH && (
              <StyledFullPriceText>
                {t('intlNumber', {
                  val: initialAmounts.course / 100,
                })}{' '}
                {currency && getCurrency(currency)}{' '}
                <StyledFullPriceTextUnderline>
                  {t('intlNumber', {
                    val: fullPrice / 100,
                  })}{' '}
                  {currency && getCurrency(currency)}
                </StyledFullPriceTextUnderline>
                <StyledFullPriceTextTag>
                  -{fullPayDiscountPercent}%
                </StyledFullPriceTextTag>
              </StyledFullPriceText>
            )}
          {formType === FormType.MODAL &&
            fullPayDiscountPercent &&
            initialAmounts && (
              <StyledFullPriceText>
                {t('intlNumber', {
                  val: initialAmounts.course / 100,
                })}{' '}
                руб.{' '}
                <StyledFullPriceTextUnderline>
                  {t('intlNumber', {
                    val: fullPrice / 100,
                  })}{' '}
                  руб.
                </StyledFullPriceTextUnderline>
                <StyledFullPriceTextTag>
                  -{fullPayDiscountPercent}%
                </StyledFullPriceTextTag>
              </StyledFullPriceText>
            )}
          {/* если не имеет подписки */}
          {/* subscription = undefined */}

          {!checkContinue() && (
            <StyledCourseCheckoutForm>
              {/* switches */}
              {(formType === FormType.FIRST ||
                formType === FormType.ORDER_ALL ||
                formType === FormType.MODAL) && (
                <StyledSwitchContainer>
                  {/* расскрочка */}
                  {installment && (
                    <StyledSwitchItem
                      onClick={() => {
                        setValue('subscriptionType', 'installment', {
                          shouldValidate: true,
                        })
                      }}
                    >
                      <StyledSwitchWrapper>
                        <StyledSwitch
                          data-ison={subscriptionTypeValue === 'installment'}
                        >
                          <StyledSwitchHandle />
                        </StyledSwitch>
                      </StyledSwitchWrapper>
                      <StyledSwitchItemInformation>
                        <StyledSwitchItemInformationTitle
                          isToggled={subscriptionTypeValue === 'installment'}
                        >
                          Купить в рассрочку
                        </StyledSwitchItemInformationTitle>
                        {installment && (
                          <StyledSwitchItemInformationDescription>
                            От {(installment.amount / 100 / 36).toFixed(0)} руб.
                            в месяц. Скидка {fullPayDiscountPercent}%
                          </StyledSwitchItemInformationDescription>
                        )}
                        <StyledTagContainer>
                          <Tag
                            label='Часто выбирают'
                            tagType={TagType.CUSTOM_1}
                            size={TagSize.PADDING}
                            beforeIcon={'success-tick'}
                          />
                        </StyledTagContainer>
                      </StyledSwitchItemInformation>
                    </StyledSwitchItem>
                  )}
                  {/* InitialPayType = курс */}
                  {initialAmounts &&
                    Object.keys(initialAmounts).includes('course') && (
                      <StyledSwitchItem
                        onClick={() => {
                          setValue('subscriptionType', 'course', {
                            shouldValidate: true,
                          })
                        }}
                      >
                        <StyledSwitchWrapper>
                          <StyledSwitch
                            data-ison={subscriptionTypeValue === 'course'}
                          >
                            <StyledSwitchHandle />
                          </StyledSwitch>
                        </StyledSwitchWrapper>
                        <StyledSwitchItemInformation>
                          <StyledSwitchItemInformationTitle
                            isToggled={subscriptionTypeValue === 'course'}
                          >
                            {tc('course')}
                          </StyledSwitchItemInformationTitle>
                          {fullPayDiscountPercent && currency && (
                            <StyledSwitchItemInformationDescription>
                              {tc('courseDescription', {
                                val: initialAmounts.course / 100,
                                currency: getCurrency(currency),
                                fullPayDiscountPercent,
                              })}
                            </StyledSwitchItemInformationDescription>
                          )}
                        </StyledSwitchItemInformation>
                      </StyledSwitchItem>
                    )}
                  {/* InitialPayType = firstFree */}
                  {initialAmounts &&
                    Object.keys(initialAmounts).includes('firstFree') &&
                    ENV_NAME !== 'prod' && (
                      <StyledSwitchItem
                        onClick={() => {
                          setValue('subscriptionType', 'firstFree', {
                            shouldValidate: true,
                          })
                        }}
                      >
                        <StyledSwitchWrapper>
                          <StyledSwitch
                            data-ison={subscriptionTypeValue === 'firstFree'}
                          >
                            <StyledSwitchHandle />
                          </StyledSwitch>
                        </StyledSwitchWrapper>
                        <StyledSwitchItemInformation>
                          <StyledSwitchItemInformationTitle
                            isToggled={subscriptionTypeValue === 'firstFree'}
                          >
                            Попробовать за 1 рубль
                          </StyledSwitchItemInformationTitle>
                          {nextBlock && (
                            <StyledSwitchItemInformationDescription>
                              {tc('further', {
                                val: Number(nextBlock.price) / 100,
                                count: Number(nextBlock.days),
                              })}
                              <br />
                              Полная стоимость {fullPrice / 100} руб.
                            </StyledSwitchItemInformationDescription>
                          )}
                        </StyledSwitchItemInformation>
                      </StyledSwitchItem>
                    )}
                  {/* InitialPayType = trial */}
                  {initialAmounts &&
                    Object.keys(initialAmounts).includes('trial') &&
                    internalization && (
                      <StyledSwitchItem
                        onClick={() => {
                          setValue('subscriptionType', 'trial', {
                            shouldValidate: true,
                          })
                        }}
                      >
                        <StyledSwitchWrapper>
                          <StyledSwitch
                            data-ison={subscriptionTypeValue === 'trial'}
                          >
                            <StyledSwitchHandle />
                          </StyledSwitch>
                        </StyledSwitchWrapper>
                        <StyledSwitchItemInformation>
                          <StyledSwitchItemInformationTitle
                            isToggled={subscriptionTypeValue === 'trial'}
                          >
                            {currency &&
                              tc('trial', {
                                val: Number(initialAmounts['trial'] / 100),
                                currency: getCurrency(currency),
                              })}
                          </StyledSwitchItemInformationTitle>
                        </StyledSwitchItemInformation>
                      </StyledSwitchItem>
                    )}
                </StyledSwitchContainer>
              )}

              {/* if не авторизирован */}
              {status === 'unauthenticated' ? (
                <StyledCourseCheckoutInput>
                  <Input
                    errorMessage={errors?.email?.message}
                    inputStyle={InputStyle.ROUNDED}
                    placeholder={tc('enterYourEmail')}
                    type='email'
                    {...register('email', {
                      required: {
                        value: true,
                        message: t('validations.common.required'),
                      },
                    })}
                  />
                </StyledCourseCheckoutInput>
              ) : null}

              {/* Checked radio === module */}
              {subscriptionTypeValue === 'module' &&
                initialAmounts &&
                !hideRadio && (
                  <StyledCourseCheckoutPrice>
                    <span>
                      {t('priceRubWeek', { val: initialAmounts.module / 100 })}
                    </span>
                  </StyledCourseCheckoutPrice>
                )}

              {/* Checked radio === firstFree */}
              {subscriptionTypeValue === 'firstFree' &&
                nextBlock &&
                (formType === FormType.FIRST || formType === FormType.SECOND) &&
                internalization && (
                  <StyledCourseCheckoutPriceFirstFree>
                    <StyledCourseCheckoutPriceDiscount>
                      <div>
                        <Trans
                          t={tc}
                          i18nKey='tryForRuble'
                          components={{
                            '1': <StyledCourseCheckoutPriceDiscountInner />,
                          }}
                        />
                      </div>
                    </StyledCourseCheckoutPriceDiscount>

                    <StyledCourseCheckoutPriceFullFirstFree>
                      {tc('further', {
                        val: Number(nextBlock.price) / 100,
                        count: Number(nextBlock.days),
                      })}
                    </StyledCourseCheckoutPriceFullFirstFree>
                  </StyledCourseCheckoutPriceFirstFree>
                )}

              {(formType === FormType.FIRST ||
                formType === FormType.SECOND ||
                formType === FormType.MODAL) && (
                <Button
                  buttonStyle={ButtonStyle.FILLED}
                  size={ButtonSize.BLOCK}
                  disabled={!isValid || isSubmitting}
                  type='submit'
                >
                  {checkContinue() && tc('continue')}
                  {checkPay() && tc('pay')}
                  {checkBuyAllModules() && tc('buyAllModules')}
                  {!checkContinue() &&
                    !checkPay() &&
                    !checkBuyAllModules() &&
                    tc('startLearning')}
                </Button>
              )}
              {(formType === FormType.THIRD ||
                formType === FormType.FOURTH ||
                formType === FormType.ORDER_SECOND ||
                formType === FormType.ORDER_ALL) && (
                <Button
                  buttonStyle={ButtonStyle.FILLED}
                  size={ButtonSize.BLOCK}
                  type='submit'
                >
                  {tc('pay')}
                </Button>
              )}

              {/* для /order */}
              {(subscriptionTypeValue === 'firstFree' ||
                subscriptionTypeValue === 'firstDelivered') &&
                nextBlock &&
                (formType === FormType.THIRD ||
                  formType === FormType.FOURTH ||
                  formType === FormType.ORDER_SECOND) && (
                  <StyledCourseCheckoutPriceFirstFreeBottom>
                    <StyledCourseCheckoutPriceFirstFreeBottomIcons>
                      <Icon name='order-visa' width='60' height='19' />
                      <Icon name='order-mir' width='64' height='19' />
                      <Icon name='order-master' width='40' height='24' />
                      <Icon name='order-maestro' width='35' height='24' />
                    </StyledCourseCheckoutPriceFirstFreeBottomIcons>
                  </StyledCourseCheckoutPriceFirstFreeBottom>
                )}
              {/* для /order/all */}
              {formType === FormType.ORDER_ALL && (
                <StyledCourseCheckoutPriceFirstFreeBottom>
                  <StyledCourseCheckoutPriceFirstFreeBottomIcons>
                    <Icon name='order-visa' width='60' height='19' />
                    <Icon name='order-mir' width='64' height='19' />
                    <Icon name='order-master' width='40' height='24' />
                    <Icon name='order-maestro' width='35' height='24' />
                  </StyledCourseCheckoutPriceFirstFreeBottomIcons>
                </StyledCourseCheckoutPriceFirstFreeBottom>
              )}
              <StyledCourseCheckoutAccept>
                <Trans
                  t={tc}
                  i18nKey='terms'
                  components={{
                    '1': (
                      <StyledCourseCheckoutAcceptLink
                        target='_blank'
                        href={PRIVACY_POLICY}
                        rel='noreferrer'
                      />
                    ),
                    '2': (
                      <StyledCourseCheckoutAcceptLink
                        target='_blank'
                        href={PERSONAL_DATA}
                        rel='noreferrer'
                      />
                    ),
                    '3': (
                      <StyledCourseCheckoutAcceptLink
                        target='_blank'
                        href={OFERTA}
                        rel='noreferrer'
                      />
                    ),
                  }}
                />
              </StyledCourseCheckoutAccept>
            </StyledCourseCheckoutForm>
          )}

          {/* для /order */}
          {(subscriptionTypeValue === 'firstFree' ||
            subscriptionTypeValue === 'firstDelivered') &&
            nextBlock &&
            (formType === FormType.THIRD ||
              formType === FormType.ORDER_SECOND ||
              formType === FormType.FOURTH) && (
              <StyledCourseCheckoutPriceFirstFreeBottom>
                <StyledCourseCheckoutPriceFullFirstFree>
                  {tc('further', {
                    val: Number(nextBlock.price) / 100,
                    count: Number(nextBlock.days),
                  })}
                </StyledCourseCheckoutPriceFullFirstFree>
              </StyledCourseCheckoutPriceFirstFreeBottom>
            )}
          {/*  если имеет subscription  */}
          {subscription &&
            subscription.status === 4 &&
            (subscription.stopReason === 2 ||
              subscription.stopReason === 1 ||
              subscription.stopReason === 7) && (
              <StyledCourseCheckoutForm>
                <Button
                  buttonStyle={ButtonStyle.OUTLINED}
                  size={ButtonSize.BLOCK}
                  onClick={e => {
                    e.preventDefault()
                    return router.push(navigateCourse)
                  }}
                >
                  {tc('continue')}
                </Button>
              </StyledCourseCheckoutForm>
            )}
        </StyledCourseCheckoutInner>
        <StyledCourseCheckoutBrand formtype={formType} />
        <StyledCourseCheckoutImage formtype={formType} />
      </StyledCourseCheckout>
    )
  },
)

Form.displayName = 'Form'
export default React.memo(Form)

const StyledCourseCheckout = styled.form<{ formType: FormType }>`
  border-radius: 5px;
  position: relative;
  padding: 48px 20px 54px;
  display: flex;
  ${({ formType }) => {
    switch (formType) {
      case FormType.FIRST:
        return css`
          padding: 64px 0;
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            position: relative;
          }

          @media ${({ theme }) => theme.devices.noteUp} {
            padding: 128px 0;
          }
        `
      case FormType.MODAL:
        return css`
          padding: 67px;
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            position: relative;
          }
        `
      case FormType.SECOND:
        return css`
          background: linear-gradient(
            95.57deg,
            #f5f5f5 1%,
            rgba(255, 255, 255, 0) 96.49%
          );
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            padding: 70px 160px 74px 99px;
          }

          @media ${({ theme }) => theme.devices.noteUp} {
            padding: 68px 160px 74px 99px;
          }
        `
      case FormType.THIRD:
        return css`
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(1%, #f5f5f5),
            color-stop(96.49%, rgba(255, 255, 255, 0))
          );
          background: linear-gradient(
            to bottom,
            #f5f5f5 1%,
            rgba(255, 255, 255, 0) 96.49%
          );
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            padding: 60px;
            position: relative;
          }

          @media ${({ theme }) => theme.devices.noteUp} {
            padding: 67px;
          }
        `
      case FormType.FOURTH:
        return css`
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(1%, #f5f5f5),
            color-stop(96.49%, rgba(255, 255, 255, 0))
          );
          background: linear-gradient(
            to bottom,
            #f5f5f5 1%,
            rgba(255, 255, 255, 0) 96.49%
          );
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            padding: 60px;
            position: relative;
          }

          @media ${({ theme }) => theme.devices.noteUp} {
            padding: 67px;
          }
        `
      case FormType.ORDER_SECOND:
        return css`
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(1%, #f5f5f5),
            color-stop(96.49%, rgba(255, 255, 255, 0))
          );
          background: linear-gradient(
            to bottom,
            #f5f5f5 1%,
            rgba(255, 255, 255, 0) 96.49%
          );
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            padding: 60px;
            position: relative;
          }

          @media ${({ theme }) => theme.devices.noteUp} {
            padding: 67px;
          }
        `
      case FormType.ORDER_ALL:
        return css`
          background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            color-stop(1%, #f5f5f5),
            color-stop(96.49%, rgba(255, 255, 255, 0))
          );
          background: linear-gradient(
            to bottom,
            #f5f5f5 1%,
            rgba(255, 255, 255, 0) 96.49%
          );
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            padding: 60px;
            position: relative;
          }

          @media ${({ theme }) => theme.devices.noteUp} {
            padding: 67px;
          }
        `
      default:
        break
    }
  }}
`

const StyledCourseCheckoutInner = styled.div<{ formType: FormType }>`
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    max-width: 540px;
  }
  ${({ formType }) => {
    switch (formType) {
      case FormType.FIRST:
        return css`
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            width: 505px;
          }
          @media ${({ theme }) => theme.devices.noteUp} {
            width: 450px;
          }
        `
      case FormType.MODAL:
        return css`
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            width: 505px;
          }
          @media ${({ theme }) => theme.devices.noteUp} {
            width: 450px;
          }
        `
      case FormType.THIRD:
        return css`
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            max-width: 100%;
            width: 100%;
          }
        `
      case FormType.FOURTH:
        return css`
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            max-width: 100%;
            width: 100%;
          }
        `
      case FormType.ORDER_SECOND:
        return css`
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            max-width: 100%;
            width: 100%;
          }
        `
      case FormType.ORDER_ALL:
        return css`
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            max-width: 100%;
            width: 100%;
          }
        `
      default:
        break
    }
  }}
`

const StyledCourseCheckoutCategory = styled.div`
  display: -webkit-box;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    text-align: left;
    margin-bottom: 30px;
    justify-content: flex-start;
  }
`

const StyledCourseCheckoutTitle = styled.h2<Pick<Props, 'formType'>>`
  text-align: center;
  margin-bottom: 24px;
  ${({ theme }) => theme.mixins.H3};

  ${({ formType }) => {
    if (
      formType === FormType.THIRD ||
      formType === FormType.FOURTH ||
      formType === FormType.ORDER_SECOND
    ) {
      return css`
        ${({ theme }) => theme.mixins.H4};
      `
    }
    if (formType === FormType.MODAL) {
      return css`
        ${({ theme }) => theme.mixins.textNormal};
        margin-bottom: 0;
      `
    }
  }}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    text-align: left;
    ${({ theme }) => theme.mixins.H2};

    ${({ formType }) => {
      if (
        formType === FormType.THIRD ||
        formType === FormType.FOURTH ||
        formType === FormType.ORDER_SECOND ||
        formType === FormType.ORDER_ALL
      ) {
        return css`
          text-align: center;
          ${({ theme }) => theme.mixins.H4};
        `
      }
      if (formType === FormType.MODAL) {
        return css`
          ${({ theme }) => theme.mixins.textButton};
        `
      }
    }}
  }
`
const StyledCourseCheckoutTitleVersion = styled(StyledCourseCheckoutTitle)`
  margin-bottom: 10px;
`

const StyledCoursedCheckoutResult = styled.div`
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.text};
  text-align: center;
  margin-bottom: 10px;
`

const StyledCoursedCheckoutFeatures = styled.ul<Pick<Props, 'formType'>>`
  list-style-type: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flex;
  display: flex;
  width: auto;
  margin: 0 0 20px;
  ${({ formType }) => {
    if (
      formType === FormType.THIRD ||
      formType === FormType.FOURTH ||
      formType === FormType.ORDER_SECOND ||
      formType === FormType.ORDER_ALL
    ) {
      return css`
        justify-content: center;
        margin-bottom: 20px;
      `
    }
  }}
`

const StyledCoursedCheckoutFeaturesItem = styled.li`
  display: -webkit-box;
  display: -ms-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  aling-item: center;
  ${({ theme }) => theme.mixins.textNormal};

  & + & {
    margin: 0 0 0 5px;
    @media ${({ theme }) => theme.devices.mobileXsUp} {
      margin: 0 0 0 44px;
    }
  }
`

const StyledCoursedCheckoutFeaturesItemImage = styled.span`
  margin-right: 10px;
`
const StyledCourseCheckoutImage = styled(BRAND)<{ formtype: FormType }>`
  position: absolute;
  top: 45%;
  right: -11%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 550px;
  height: 412px;
  display: none;
  @media ${({ theme }) => theme.devices.noteUp} {
    display: block;
  }
  ${({ formtype }) => {
    switch (formtype) {
      case FormType.FIRST:
        return css`
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            display: none;
        `
      case FormType.MODAL:
        return css`
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            display: none;
        `
      case FormType.THIRD:
        return css`
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            display: none;
          }
        `
      case FormType.FOURTH:
        return css`
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            display: none;
          }
        `
      case FormType.ORDER_SECOND:
        return css`
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            display: none;
          }
        `
      case FormType.ORDER_ALL:
        return css`
          @media ${({ theme }) => theme.devices.mobileLgUp} {
            display: none;
          }
        `
      default:
        break
    }
  }}
`

const StyledCourseCheckoutBrand = styled(BRANDLOGO)<{ formtype: FormType }>`
  ${({ formtype }) => {
    switch (formtype) {
      case FormType.FIRST:
        return css`
          display: none;
        `
      case FormType.MODAL:
        return css`
          display: none;
        `
      case FormType.SECOND:
        return css`
          display: none;
        `
      case FormType.THIRD:
        return css`
          display: none;
        `
      case FormType.FOURTH:
        return css`
          display: none;
        `
      case FormType.ORDER_SECOND:
        return css`
          display: none;
        `
      case FormType.ORDER_ALL:
        return css`
          display: none;
        `
      default:
        break
    }
  }}
`

const StyledCourseCheckoutForm = styled.div``

const StyledCourseCheckoutInput = styled.div`
  margin-bottom: 19px;
`

const StyledCourseCheckoutPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  line-height: 31px;
  font-size: 1.563rem;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin: 17px 0;
    flex-direction: row;
  }
`

const StyledCourseCheckoutPriceFirstFree = styled(StyledCourseCheckoutPrice)`
  text-align: center;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-weight: 600;
    line-height: 31px;
    font-size: 1.563rem;
    margin: 24px 0;
    flex-direction: column;
    align-items: start;
  }
`

const StyledCourseCheckoutPriceFirstFreeBottom = styled(
  StyledCourseCheckoutPriceFirstFree,
)`
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    align-items: start;
  }
`

const StyledCourseCheckoutPriceDiscount = styled.div`
  display: flex;
  display: -webkit-box;
  display: -ms-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  aling-item: center;
`

const StyledCourseCheckoutPriceDiscountInner = styled.span`
  display: block;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    display: inline-block;
  }
`

// const StyledCourseCheckoutPriceTag = styled.div`
//   margin-right: 19px;
// `

const StyledCourseCheckoutPriceFull = styled.div`
  color: ${({ theme }) => theme.colors.grey};
  text-decoration: line-through;
  margin-top: 20.75px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-left: 20px;
    margin-top: 0;
  }
`

const StyledCourseCheckoutPriceFullFirstFree = styled(
  StyledCourseCheckoutPriceFull,
)`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 15px;
  text-decoration: none;
  margin-top: 10px;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-left: 0;
  }
`

const StyledCourseCheckoutAccept = styled.div`
  margin-top: 24px;
  width: fit-content;
  font-size: 0.75rem;
  line-height: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
`

const StyledCourseCheckoutAcceptLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.primary};
  &:visited {
    text-decoration: none;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const StyledCourseCheckoutPriceFirstFreeBottomIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  svg:not(:last-child) {
    margin-right: 19px;
  }
`

const StyledCourseCheckoutTimerInForm = styled.div`
  margin-bottom: 15px;
  margin: 0 auto 15px;
  display: grid;
  grid-template-columns: 145px auto;
  grid-template-row: 1fr 1fr 1fr;
  max-width: 263px;
`

const StyledCourseCheckoutTimerText = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.warning};
`

const StyledCourseCheckoutTimerRuble = styled.div`
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  text-align: center;
  align-self: end;
  color: ${({ theme }) => theme.colors.primary};
`

const StyledCourseCheckoutTimerSale = styled.div`
  text-align: center;
  align-self: center;
  margin-top: 10px;
`

const StyledCourseCheckoutTimerSaleTag = styled.div`
  margin-top: 10px;
  justify-self: center;
`

const StyledCourseCheckoutTimerCount = styled.div`
  font-weight: 300;
  font-size: 40px;
  line-height: 49px;
  text-align: center;
`

const StyledCourseCheckoutTimerTotalPrice = styled.div`
  text-decoration: line-through;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
`

const StyledCourseCheckoutTitleForOrder = styled.h3<Pick<Props, 'formType'>>`
  text-align: center;
  ${({ theme }) => theme.mixins.H4};
  margin-bottom: 17px;
  ${({ formType }) => {
    if (formType === FormType.MODAL) {
      return css`
        ${({ theme }) => theme.mixins.H3};
        text-align: left;
      `
    }
  }}
`
// order-b
const StyledCourseCheckoutTimerInFormB = styled.div`
  margin: 0 auto 15px;
  display: grid;
  grid-template-columns: 145px auto;
  grid-template-row: auto;
  max-width: 263px;
  grid-template-areas:
    'header header'
    'description description'
    'count payment';
`
const StyledCourseCheckoutDescriptionB = styled.div`
  grid-area: header;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.text};
  text-align: center;
`

const StyledCourseCheckoutTimerTextB = styled(StyledCourseCheckoutTimerText)`
  margin-top: 4.2px;
  grid-area: description;
`

const StyledCourseCheckoutTimerCountB = styled(StyledCourseCheckoutTimerCount)`
  grid-area: count;
`

const StyledCourseCheckoutTimerRubleB = styled(StyledCourseCheckoutTimerRuble)`
  grid-area: payment;
  align-self: center;
`
// ordersecond
const StyledCourseCheckoutSecond = styled.div``

const StyledCourseCheckoutSecondPay = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  margin-top: 17px;
  margin-bottom: 21px;
`

const StyledCourseCheckoutSecondDesc = styled.div`
  text-align: center;
  margin-bottom: 22px;
`

const StyledSwitchHandle = styled(motion.div)`
  width: 17.72px;
  height: 18px;
  background-color: #2e2e2e;
  border-radius: 50px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 24px;
    height: 24px;
  }
`

const StyledSwitchItem = styled.li`
  ${({ theme }) => theme.mixins.flexbox()}
  gap: 16px;
  cursor: pointer;
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    gap: 32px;
  }
`

const StyledSwitchItemInformation = styled.div``

const StyledTagContainer = styled.div`
  margin-top: 8px;
`

const StyledSwitchItemInformationTitle = styled.h3<{ isToggled: boolean }>`
  ${({ theme }) => theme.mixins.H5};
  color: ${({ isToggled, theme }) =>
    isToggled ? theme.colors.primary : theme.colors.black};
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3};
  }
`

const StyledSwitchItemInformationDescription = styled.div`
  margin-top: 4px;
  ${({ theme }) => theme.mixins.textSmall};

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 8px;
    ${({ theme }) => theme.mixins.textNormal};
  }
`

const StyledSwitchContainer = styled.ul`
  margin-top: 30px;
  margin-bottom: 32px;
  ${StyledSwitchItem}:not(:last-child) {
    margin-bottom: 24px;
  }

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    margin-top: 40px;
  }
`

// switch

const StyledSwitchWrapper = styled.div`
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    padding-top: 7px;
  }
`

const StyledSwitch = styled.div`
  ${({ theme }) => theme.mixins.flexShrink(0)}
  width: 48px;
  height: 24px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  border-radius: 28px;
  padding: 2px 2.5px;
  cursor: pointer;
  border: 1px solid #2e2e2e;

  &[data-ison='true'] {
    justify-content: flex-end;

    ${StyledSwitchHandle} {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    width: 65px;
    height: 32px;
    padding: 3px 4px;
  }
`

const StyledFullPriceText = styled.div`
  ${({ theme }) => theme.mixins.H4}
  @media ${({ theme }) => theme.devices.mobileLgUp} {
    ${({ theme }) => theme.mixins.H3}
  }
`

const StyledFullPriceTextUnderline = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-decoration-line: line-through;
  color: #757575;
`

const StyledFullPriceTextTag = styled.span`
  background: #fb432e;
  border-radius: 100px;
  font-weight: 600;
  color: #ffffff;
  padding: 0 5px;
  margin-left: 10px;
  font-size: 15px;

  @media ${({ theme }) => theme.devices.mobileLgUp} {
    font-size: 20px;
    line-height: 25px;
    padding: 0 11px;
  }
`
